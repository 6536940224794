import { Icon } from "@root/shared/components/Icon";
import Modal from "@root/shared/components/Modal";
import { Colors } from "@root/utils/colors";
import { FC } from "react";
import { LineLink } from "@userFrontend/components/Links/LineLink";
import { ScrollFloatingButton } from "@root/shared/components/ScrollFloatingButton";
import { Link } from "@root/shared/components/Link";
import * as styles from "./styles.css";

type Props = {
  isShow: boolean;
  onClose?: () => void;
  isLineLink: boolean;
  lineUrl: string;
  isAppInstalled: boolean;
  appUrl: string;
  onClick: () => void;
  gaLabel: string;
};

export const Presentation: FC<Props> = ({
  isShow,
  onClose,
  isLineLink,
  lineUrl,
  isAppInstalled,
  appUrl,
  onClick,
  gaLabel,
}) => {
  return (
    <>
      <ScrollFloatingButton
        iconName="light"
        text="新着求人を受け取る"
        onClick={onClick}
        gaAction="app_line_promotion"
        gaLabel={gaLabel}
        buttonWidth={161}
      />
      <Modal isShow={isShow} onClose={onClose} headerTitleStyle="none" footerContentStyle="none">
        <div className={styles.modalContents}>
          <div className={styles.imageWrapper}>
            <Icon name="light" fontSize="44px" color={Colors.TEXT_DEEP} />
          </div>
          <p className={styles.text}>
            希望条件を設定して
            <br />
            新着求人を受け取ろう
          </p>
          {isLineLink && <LineLink url={lineUrl} text="LINEで友だち追加" />}
          {isAppInstalled && (
            <div className={styles.buttonWrapper}>
              <Link theme="primary" href={appUrl} target="_blank">
                <div className={styles.iconWrapper}>
                  <Icon name="phone" />
                </div>
                アプリをインストール
              </Link>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
