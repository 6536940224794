import { FC, useState } from "react";
import { Accordion } from "@root/shared/components/Accordion";
import { ChoiceChip } from "@root/shared/components/ChoiceChip/legacy";
import * as styles from "./styles.css";

type SkillProps = {
  id: number;
  name: string;
};

type SkillCategoryProps = {
  name: string;
  skills: SkillProps[];
};

type Props = {
  skillCategories: SkillCategoryProps[];
  initialCheckedIds: number[];
  isFormError: boolean;
};

export const SkillAccordionList: FC<Props> = ({
  skillCategories,
  initialCheckedIds,
  isFormError,
}) => {
  const categoryNames = skillCategories.map((category) => category.name);
  const initialAccordionOpenMap = Object.fromEntries(
    categoryNames.map((categoryName) => [categoryName, isFormError]),
  );
  const [accordionOpenMap, setAccordionOpenMap] = useState<{ [key: string]: boolean }>(
    initialAccordionOpenMap,
  );

  const toggleAccordion = (categoryName: string) => {
    setAccordionOpenMap((prevState) => ({
      ...prevState,
      [categoryName]: !prevState[categoryName],
    }));
  };

  const isSkillChecked = (skillId: number) => {
    return initialCheckedIds?.includes(skillId);
  };

  return (
    <>
      {skillCategories.map((category) => (
        <div key={category.name} className={styles.wrapper}>
          <Accordion
            title={category.name}
            accordionTheme="panel"
            isOpened={accordionOpenMap[category.name] || false}
            onClick={() => toggleAccordion(category.name)}
          >
            <ul className={styles.ul}>
              {category.skills.map((skill) => (
                <li key={skill.id} className={styles.li}>
                  <ChoiceChip
                    id={`after_user_skills_ids_${skill.id}`}
                    value={skill.id}
                    labelName={skill.name}
                    name="form_user_frontend_job_entry[after_user_skills_ids][]"
                    defaultChecked={isSkillChecked(skill.id)}
                  />
                </li>
              ))}
            </ul>
          </Accordion>
        </div>
      ))}
    </>
  );
};
