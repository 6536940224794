import { FC } from "react";
import { Salaries, SalariesProps } from "@legacy_user_frontend/components/ui_parts/salaries";
import { StyledElements } from "./src/styled_elements";
import { Shop } from "./src/shop";
import { ShopAccordion, ShopAccordionProps } from "./src/shop_accordion";

interface RequirementsProps {
  requirements: {
    clientShops: {
      totalNumber: ShopAccordionProps["totalNumber"];
      shopInformations: ShopAccordionProps["shopInformations"] | [];
    };
    salaries: SalariesProps["salaries"] | [];
  };
  clientShopsEvent: {
    clientShopsGaAction: ShopAccordionProps["gaAction"];
    clientShopsGaLabels: ShopAccordionProps["gaLabels"];
  };
  isSp: boolean;
  query: string;
  className?: string;
}

export const SalaryAndShopRequirements: FC<RequirementsProps> = ({
  requirements: {
    salaries,
    clientShops: { totalNumber, shopInformations },
  },
  clientShopsEvent: { clientShopsGaAction, clientShopsGaLabels },
  isSp,
  query,
  className,
}) => (
  <StyledElements className={className}>
    <dl className="table">
      <dt>
        <span>
          <i className="icon-yen" />
          <span className="table-title">給与</span>
        </span>
      </dt>
      <dd>{salaries && salaries.length > 0 && <Salaries salaries={salaries} />}</dd>
      <dt>
        <span>
          <i className="icon-location" />
          <span className="table-title">店舗</span>
        </span>
      </dt>
      <dd className="js-link-expand__unlink">
        {totalNumber > 1 && (
          <p className="shop-num-outer">
            <span className="shop-num">{totalNumber}</span>
            店舗で募集中
          </p>
        )}
        {((isSp && totalNumber > 1) || (!isSp && totalNumber > 3)) &&
          shopInformations.length > 0 && (
            <ShopAccordion
              totalNumber={totalNumber}
              shopInformations={shopInformations}
              gaAction={clientShopsGaAction}
              gaLabels={clientShopsGaLabels}
              isSp={isSp}
              query={query}
            />
          )}
        {((isSp && totalNumber <= 1) || (!isSp && totalNumber <= 3)) &&
          shopInformations.length > 0 && (
            <Shop
              totalNumber={totalNumber}
              shopInformations={shopInformations}
              gaAction={clientShopsGaAction}
              gaLabels={clientShopsGaLabels}
              isSp={isSp}
              query={query}
            />
          )}
      </dd>
    </dl>
  </StyledElements>
);
