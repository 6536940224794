import { FC, lazy, Suspense } from "react";

const BarComponent = lazy(() => import("react-chartjs-2").then(({ Bar }) => ({ default: Bar })));

interface Props {
  histogramData: HistogramProps;
}

interface HistogramProps {
  keys: string[];
  ratios: number[];
  avgFlags: boolean[];
}

export const SearchSalaryHistogram: FC<Props> = ({ histogramData: { keys, ratios, avgFlags } }) => {
  const avgBarColor = "rgba(255, 167, 118, 1)";
  const defaultBarColor = "rgba(209, 224, 252, 1)";
  const backgroundColor: string[] = [];
  avgFlags.forEach((flag) => {
    if (flag) {
      backgroundColor.push(avgBarColor);
    } else {
      backgroundColor.push(defaultBarColor);
    }
  });
  const data = {
    labels: keys,
    datasets: [
      {
        label: "給与",
        data: ratios,
        backgroundColor,
        barPercentage: 1.0,
        categoryPercentage: 0.9,
      },
    ],
  };
  const options = {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            tickMarkLength: 8,
            offsetGridLines: false,
            color: "#666",
          },
          ticks: {
            callback: (value: string, index: number) => {
              if (index === 0 || index === keys.length - 1) {
                return value;
              }
              return null;
            },
            fontSize: 12,
            fontColor: "#666",
            maxRotation: 0,
            minRotation: 0,
            padding: 4,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: "transparent",
            drawBorder: false,
            tickMarkLength: 0,
            zeroLineColor: "#666",
            z: 1,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
    hover: {
      mode: null,
    },
    tooltips: {
      enabled: false,
    },
  };
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BarComponent data={data} options={options} width={100} height={25} />
    </Suspense>
  );
};
