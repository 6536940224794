export const uniqKeyValueObjects = <
  T extends {
    key: string;
    value: number;
  },
>(
  objects: T[],
): T[] => {
  const uniqueObjects = Array.from(new Map(objects?.map((line) => [line.key, line])));
  return uniqueObjects.map((line) => line[1]);
};

export const ToSnakeCase = (text: string): string =>
  text
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
