import { FC } from "react";
import { Variant } from "@root/shared/components/ABTestRenderer";
import { OsType, SiteType } from "@root/shared/types/type";
import { APattern } from "./variables/APattern";
import { BPattern } from "./variables/BPattern";
import { mainTextStyle } from "./styles.css";
import { GaScreenName } from "./type";

type Props = {
  isLogin: boolean;
  downloadUrl: string;
  osType: OsType;
  siteType: SiteType;
  gaScreenName: GaScreenName;
  requestFullPath: string;
};

export const SmartAppBanner: FC<Props> = ({
  isLogin,
  osType,
  siteType,
  downloadUrl,
  gaScreenName,
  requestFullPath,
}) => {
  // ABテスト実施後、画面に応じて固定でバナーを返却する形に修正(REJOB_DEV_DOING-11439)
  // TODO: 最低限の改修のみのため、ABテスト用の記述削除等のリファクタリングは後ほど実施予定

  // ログイン画面では「オリジナル訴求」のバナーを返却
  if (gaScreenName === "login") {
    return (
      <Variant id="appeal_original">
        <APattern
          isLogin={isLogin}
          downloadUrl={downloadUrl}
          gaScreenName={gaScreenName}
          osType={osType}
          siteType={siteType}
          requestFullPath={requestFullPath}
        />
      </Variant>
    );
  }
  // 検索一覧画面では「スムーズさ訴求」のバナーを返却
  if (gaScreenName === "search_result_list") {
    return (
      <Variant id="appeal_smoothness">
        <BPattern
          isLogin={isLogin}
          downloadUrl={downloadUrl}
          gaScreenName={gaScreenName}
          siteType={siteType}
          gaAppealPattern="appeal_smoothness"
        >
          <span className={mainTextStyle}>
            アプリならサクサク動作で
            <br />
            使いやすい！
          </span>
        </BPattern>
      </Variant>
    );
  }
  // それ以外の画面(求人詳細、マイページ、その他)では「採用率訴求」のバナーを返却
  return (
    <Variant id="appeal_employment_rate">
      <BPattern
        isLogin={isLogin}
        downloadUrl={downloadUrl}
        gaScreenName={gaScreenName}
        siteType={siteType}
        gaAppealPattern="appeal_employment_rate"
      >
        <>
          <span className={mainTextStyle}>アプリなら採用率1.75倍！</span>
        </>
      </BPattern>
    </Variant>
  );
};
