import "app/packs/userFrontend/features/publication/components/DetailPageActionButtons/uiParts/DefaultStyleButtons/styles.css.ts.vanilla.css!=!../../../../../../../../../../../shared/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"app/packs/userFrontend/features/publication/components/DetailPageActionButtons/uiParts/DefaultStyleButtons/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61Uy26rMBDd5ytmU6mVSsQrhBDpqn9SGewQ3xKDjNPSXvXfLxCg+AVZdOOF55wz4zMz3r56FfV2X5EH/zYAOaoS8PyqOW6+N9sx5qsxAEzrqkCfCeSc4u7ig2JxbuOu+yBxg55ri4Z99ExofhYJhLGSeLccjnRtFQ1Qckx4G5aY+3uYp4I0Tn3mlL0l4Er8w4IjHa27QAXNmUMFudQJENa79PdaC3r6dLKSCcLaXFl7Ei5po/XaZujUYNH0aH/WmfAgW5/1REEa4fSVGmvB81p6haVcMyJZNWgGPi2A06LM3rqbC+I5ZY4oTRPqQXoVomTPMLu6Gan1xT6pfwaKavbLhWCKoM44IQwQw/B4aUsZhPZRC3vqiVt5mwxr8j1HhQNqcDDQADsZ4GsAIgM8DbBgjGype6OaH4uan8futcemg9w4JvGDVga2QNa8PcSu1VvrLknGj11X9nGO7ff8g3fj151a8b7aTIvmsOMSNzB0sDN+MjSM3Ko52tphGBblY7CJDQmvrCbCOle/oRVNwzRR3xF/dJwR4D5pnP3AkX5YyqigqJBSBsfVOleTxdK03z4PQFdR3ky+0wVJEimSnZqkFfixgZbeYfvqc/BviBBlH0eNxU6f7sg8Pdz8u/wHHmIDE28IAAA=\"}!../../../../../../../../../../../shared/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var acceptButtonWrapper = '_1pi15z67';
export var buttonMaxWidth = 'var(--_1pi15z60)';
export var buttonWrapper = '_1pi15z61';
export var deadlineWrapper = '_1pi15z6c';
export var declineButtonWrapper = '_1pi15z6b';
export var entryButtonWrapper = '_1pi15z63';
export var flexButtonWrapper = '_1pi15z62';
export var hasEntriedButtonWrapper = '_1pi15z6a';
export var hasEntriedWrapper = '_1pi15z69';
export var isScoutextendButtonWrapper = '_1pi15z6d';
export var justifyCenterButtonWrapper = '_1pi15z68';
export var keepButtonWrapper = '_1pi15z65';
export var lineButtonWrapper = '_1pi15z6f';
export var notScoutedSalonTourButtonWrapper = '_1pi15z64';
export var salonTourButtonWrapper = '_1pi15z66';
export var scoutExtendDeclineWrapper = '_1pi15z6e';