// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const newrelic: any;

export const FetchJson = <T>(
  url: string,
  params?: Record<string, string | string[]>,
  method?: "GET" | "POST",
): Promise<T | null> => {
  const queryParams = new URLSearchParams();
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => queryParams.append(`${key}[]`, String(v)));
      } else {
        queryParams.append(key, String(value));
      }
    });
  }
  const urlWithParams = params ? `${url}?${queryParams}` : url;
  const postOptions = {
    method,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: `${queryParams}`,
  };

  return fetch(method === "POST" ? url : urlWithParams, method === "POST" ? postOptions : undefined)
    .then((res) => {
      if (!res.ok) {
        if (typeof newrelic !== "undefined") {
          newrelic.noticeError(res.statusText);
          return null;
        }
      }
      return res.json();
    })
    .then((json) => {
      // アプリケーション側のエラー捕捉(エラー時は{status: boolean, data: [], message: string}が返ってくる)
      if (json.status !== undefined && !json.status) {
        if (typeof newrelic !== "undefined") {
          newrelic.noticeError(json.message);
        }
        return null;
      }
      return json;
    })
    .catch((err) => {
      if (typeof newrelic !== "undefined") {
        newrelic.noticeError(err);
      }
      return null;
    });
};
