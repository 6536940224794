import { FC } from "react";
import { ActionProps } from "@legacy_user_frontend/components/pages/jobs/job_search_navigation/src/search_modal/src/search_modal_props";
import { Lists } from "./ui_parts/lists";
import {
  StyledOuter,
  StyledParentOuter,
  StyledParentText,
  StyledChildLists,
} from "./src/styled_elements";

export type BusinessTypeModalProps = {
  businessTypeContents: {
    name: string;
    occupations: {
      value: string;
      url: string;
      name: string;
      showLink: boolean;
    }[];
  }[];
  dispatchFormState: React.Dispatch<ActionProps>;
  formState: {
    [index: string]: { url: string };
  };
};

export const BusinessTypeModal: FC<BusinessTypeModalProps> = ({
  businessTypeContents,
  dispatchFormState,
  formState,
}) => {
  const handleOnBusinessTypeStateChange = (e: React.ChangeEvent<HTMLInputElement>, url: string) => {
    if (e.currentTarget.checked) {
      dispatchFormState({
        type: "selectBusinessType",
        value: e.target.value,
        url,
      });
    } else {
      dispatchFormState({ type: "unSelectBusinessType", value: e.target.value });
    }
  };
  return (
    <>
      {businessTypeContents.map(({ name, occupations }) => (
        <StyledOuter key={name}>
          <StyledParentOuter>
            <StyledParentText>{name}</StyledParentText>
          </StyledParentOuter>
          <dd>
            <StyledChildLists>
              <Lists
                contents={occupations}
                formState={formState}
                changeEvent={handleOnBusinessTypeStateChange}
                checkBoxName="q[business_type][]"
                type="checkbox"
              />
            </StyledChildLists>
          </dd>
        </StyledOuter>
      ))}
    </>
  );
};
