import { FC, useEffect, useState } from "react";
import { useHistoryBack } from "@userFrontend/hooks/useHistoryBack";
import { Presentation } from "./Presentation";

type Props = {
  isBiyo: boolean;
  bannerUrl: string;
  gaAction?: string;
};

const HAS_SHOWN_COOKIE_EXPIRE_DATE = 7;
const HAS_SHOWN_COOKIE_EXPIRE_DATE_MS = HAS_SHOWN_COOKIE_EXPIRE_DATE * 24 * 60 * 60 * 1000;

const NEVER_SHOW_COOKIE_EXPIRE_DATE = 365;
const NEVER_SHOW_COOKIE_EXPIRE_DATE_MS = NEVER_SHOW_COOKIE_EXPIRE_DATE * 24 * 60 * 60 * 1000;

const BIYO_LINE_LOGIN_LINK =
  "https://autoline.link/rejob/apis/line_login_link?register_from=rejob_site__leave_popup";
const CARE_LINE_LOGIN_LINK =
  "https://autoline.link/rejob/apis/line_login_link?register_from=rejob_site_care__leave_popup";

export const SpLineBannerModal: FC<Props> = ({ isBiyo, bannerUrl, gaAction }) => {
  const url = isBiyo ? BIYO_LINE_LOGIN_LINK : CARE_LINE_LOGIN_LINK;
  const date = Date.now();
  const hasShownExpireDateMs = new Date(date + HAS_SHOWN_COOKIE_EXPIRE_DATE_MS).toUTCString();
  const hasShownCookie = `hasShownLineFriendModal=true; Expires=${hasShownExpireDateMs}`;
  const neverShowExpireDateMs = new Date(date + NEVER_SHOW_COOKIE_EXPIRE_DATE_MS).toUTCString();
  const neverShowCookie = `neverShowLineFriendModal=true; Expires=${neverShowExpireDateMs}`;
  const isExit = useHistoryBack();
  const [isShow, setIsShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const clickBanner = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    document.cookie = neverShowCookie;
    setIsShow(false);
  };

  const closeModalAndHistoryBack = () => {
    setIsShow(false);
    if (isChecked) {
      document.cookie = neverShowCookie;
    }
    window.history.back();
  };

  const changeCheckState = () => {
    setIsChecked((prevIsChecked) => !prevIsChecked);
  };

  useEffect(() => {
    if (!isExit) {
      return;
    }
    setIsShow(true);
    document.cookie = hasShownCookie;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExit]);

  return isShow ? (
    <Presentation
      gaAction={gaAction}
      url={url}
      bannerUrl={bannerUrl}
      onClickBanner={clickBanner}
      onChangeCheckbox={changeCheckState}
      checked={isChecked}
      onClose={closeModalAndHistoryBack}
    />
  ) : null;
};
