import { ActionProps } from "./src/search_modal_props";

/*
  再検索モーダル 検索
*/
export interface formStateProps {
  [prefecture: string]: {
    [index: string]: { url: string };
  };
  businessTypes: {
    [index: string]: { url: string };
  };
  cities: {
    [index: string]: { url: string };
  };
  stations: {
    [index: string]: { url: string };
  };
}

// 現在の検索条件のクエリをarrayにする
export const convertSearchUrlToConditionArray = (
  searchUrl: string,
): { name: string; value: string }[] => {
  const enteredData = decodeURIComponent(searchUrl).replace(/.*?\?/g, "").split("&");
  const enteredDataArray = [];
  const serializeArrayData: { name: string; value: string }[] = [];
  const singleSearchKeys = [
    "keywords",
    "salary_type",
    "salary_min",
    "salary_max",
    "salon_tour_flag",
    "client_end_up_close_flag",
    "sort",
    "pref",
    "distance_meter",
    "latitude",
    "longitude",
    "city_distance_hybrid_search_flag",
  ];
  for (let i = 0; i < enteredData.length; i += 1) {
    enteredDataArray[i] = enteredData[i].split("=");
  }
  enteredDataArray.forEach((value) => {
    if (singleSearchKeys.includes(value[0])) {
      return serializeArrayData.push({ name: `q[${value[0]}]`, value: value[1] });
    }
    return serializeArrayData.push({ name: `q[${value[0]}][]`, value: value[1] });
  });
  return serializeArrayData;
};

// 再検索モーダルで選択した条件のクエリをarrayにして、現在の検索条件のクエリのarrayと合わせて返す
export const getSerializeQueryArrayData = (
  state: formStateProps,
  searchUrl: string,
  modalType: string,
): { name: string; value: string }[] => {
  const firstUrlSerializeArrayData = convertSearchUrlToConditionArray(searchUrl);
  const serializeArrayData: { name: string; value: string }[] = [];
  const keywords: { [index: string]: string } = {
    prefecture: "q[pref]",
    businessTypes: "q[business_type][]",
    cities: "q[city][]",
    stations: "q[station][]",
  };

  Object.keys(keywords).forEach((keyword) => {
    if (Object.keys(state[keyword]).length) {
      for (let i = 0; i < Object.keys(state[keyword]).length; i += 1) {
        serializeArrayData.push({ name: keywords[keyword], value: Object.keys(state[keyword])[i] });
      }
    }
  });

  // 駅モーダルで駅名チェック時は駅名と職種のみを求人数リクエストに渡すようにする
  if (serializeArrayData.length && modalType === "station") {
    return serializeArrayData.concat(
      firstUrlSerializeArrayData.filter((data) => {
        return data.name === "q[business_type][]";
      }),
    );
  }

  return firstUrlSerializeArrayData.concat(serializeArrayData);
};

// 検索ボタンの遷移先URLを返す
export const getSearchModalSubmitUrl = (searchUrl: string, formState: formStateProps): string => {
  const num = searchUrl.indexOf("?");
  const siteUrl = searchUrl.slice(0, num + 1);
  const query = searchUrl.slice(num + 1);
  let URL = "";
  const keywords: { name: string; value: string }[] = [
    { name: "prefecture", value: "" },
    { name: "businessTypes", value: "business_type=" },
    { name: "cities", value: "city=" },
    { name: "stations", value: "station=" },
  ];

  keywords.forEach((keyword) => {
    if (URL !== "" && !Object.keys(formState[keyword.name]).length) {
      return;
    }
    // 条件が一つしか選択されていない時は、サーバー側からpropsとして受け取った静的URLへ遷移する
    if (
      Object.keys(formState[keyword.name]).length === 1 &&
      formState[keyword.name][Object.keys(formState[keyword.name])[0]].url
    ) {
      URL = formState[keyword.name][Object.keys(formState[keyword.name])[0]].url;
      return;
    }
    // 条件が複数選択されている時は、クエリを作成して遷移させる
    const result = Object.keys(formState[keyword.name]).toString().replace(/,/g, ".");
    URL = `${siteUrl}${keyword.value}${result}&${query}`;
  });

  return URL;
};

/*
選択条件のstate管理
*/
export const initialState: formStateProps = {
  businessTypes: {},
  prefecture: {},
  cities: {},
  stations: {},
};

const addArrayItem = (array: { [key: string]: { url: string } }, key: string, url: string) => {
  const item = { [key]: { url } };
  return Object.assign(array, item);
};
const removeArrayItem = (array: { [key: string]: { url: string } }, key: string) => {
  const prevArray = array;
  const item = delete prevArray[key];
  return Object.assign(array, item);
};
const addItem = (key: string, url: string) => {
  const item = { [key]: { url } };
  return item;
};

// 追加された検索条件をstateに保存
export const formStateReducer = (state: formStateProps, action: ActionProps): formStateProps => {
  switch (action.type) {
    case "selectPref":
      return { ...state, prefecture: addItem(action.value, action.url) };
    case "selectBusinessType":
      return {
        ...state,
        businessTypes: addArrayItem(state.businessTypes, action.value, action.url),
      };
    case "unSelectBusinessType":
      return {
        ...state,
        businessTypes: removeArrayItem(state.businessTypes, action.value),
      };
    case "selectCities":
      return {
        ...state,
        cities: addArrayItem(state.cities, action.value, action.url),
      };
    case "unSelectCities":
      return { ...state, cities: removeArrayItem(state.cities, action.value) };
    case "selectStations":
      return {
        ...state,
        stations: addArrayItem(state.stations, action.value, action.url),
      };
    case "unSelectStations":
      return { ...state, stations: removeArrayItem(state.stations, action.value) };
    default:
      return state;
  }
};
