/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, useState, useEffect, useRef, lazy, Suspense, useCallback } from "react";
import { useForm, FormProvider, useFieldArray, SubmitHandler } from "react-hook-form";
import { SubmitAndCancelButtons } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/submit_and_cancel_buttons";
import { AdditionButton } from "@userFrontend/components/Buttons/AdditionButton";
import { ToSnakeCase } from "@legacy_root/utils";
import { showAlertBeforeBrowserBack } from "@legacy_root/user_frontend/components/pages/mypage/profiles/utils/show_alert_before_browser_back";
import { SchoolLogsCassette } from "./src/ui_parts/school_logs_cassette";
import { SchoolLogsEditProps, InputProps } from "./src/school_logs_edit_props";
import { StyledAdditionButtonOuter } from "./src/styled_elements";

const CancelModal = lazy(
  () => import("@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/cancel_modal"),
);

export const SchoolLogsEdit: FC<SchoolLogsEditProps> = ({
  action,
  graduatedYearList,
  graduatedMonthList,
  schools,
  returnPath,
  appOsType,
}) => {
  const formName = "form_user_frontend_user_schools_update.user_schools_attributes";
  // 初期値の設定
  const defaultValues = schools.map((value) => ({
    school_name: value.schoolName,
    course: value.course,
    graduated_year: value.graduatedYear,
    graduated_month: value.graduatedMonth,
    will_graduate_flag: value.isWillGraduate,
  }));
  const methods = useForm<InputProps>({
    defaultValues: {
      authenticity_token: "",
      _method: "put",
      form_user_frontend_user_schools_update: {
        user_schools_attributes: defaultValues,
      },
    },
  });

  // サーバー側から渡ってきたエラーメッセージを項目に反映する
  const { setError } = methods;
  useEffect(() => {
    const setErrorMessage = (key: string, index: number) => {
      const snakeCaseKey = ToSnakeCase(key);
      const errorFormName = `${formName}.${index}.${snakeCaseKey}` as keyof InputProps;
      setError(errorFormName, {
        type: "manual",
        message: schools[index].errors[key],
      });
    };
    schools.forEach((school, index) => {
      const schoolErrors = school.errors;
      const errorKeys = Object.keys(schoolErrors);
      if (errorKeys.length) {
        errorKeys.map((key) => setErrorMessage(key, index));
      }
    });
  }, [schools, setError]);

  // 学歴のfieldArray作成
  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: formName,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const appendCassette = useCallback(() => {
    append(
      {
        school_name: "",
        course: "",
        graduated_year: "",
        graduated_month: "",
        will_graduate_flag: false,
      },
      { shouldFocus: true },
    );
  }, [append]);

  // 学歴カセットは最低でも1つ表示する
  useEffect(() => {
    if (fields.length === 0) {
      appendCassette();
    }
  }, [appendCassette, fields, methods]);

  // URLにアンカーリンクがあった場合の対応
  useEffect(() => {
    const anchor = window.location.hash;
    if (!anchor) {
      return;
    }
    // 一覧画面で追加ボタンを押して遷移した場合はカセットを1つ追加し、新しいカセットにスクロールする
    if (anchor === `#cassette-${fields.length}`) {
      append(
        {
          school_name: "",
          course: "",
          graduated_year: "",
          graduated_month: "",
          will_graduate_flag: false,
        },
        { shouldFocus: true },
      );
      return;
    }
    // それ以外の場合は対応するカセットにスクロールする
    window.location.href = anchor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 変更の検出
  const watchedValues = methods.watch(formName);
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    const formatedDefaultValues = defaultValues;
    if (!defaultValues.length) {
      formatedDefaultValues.push({
        school_name: "",
        course: "",
        graduated_year: "",
        graduated_month: "",
        will_graduate_flag: false,
      });
    }
    const formatedWatchedValues = watchedValues.map((value) => {
      // 初期値から変更があり、かつ選択済みの場合にstringになるため、値を見てbooleanを返す
      if (value.will_graduate_flag) {
        return {
          school_name: value.school_name,
          course: value.course,
          graduated_year: value.graduated_year,
          graduated_month: value.graduated_month,
          will_graduate_flag: true,
        };
      }
      return {
        school_name: value.school_name,
        course: value.course,
        graduated_year: value.graduated_year,
        graduated_month: value.graduated_month,
        will_graduate_flag: false,
      };
    });
    if (JSON.stringify(formatedWatchedValues) !== JSON.stringify(formatedDefaultValues)) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [defaultValues, watchedValues]);

  // キャンセルボタン押下時に確認モーダルを表示する
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    if (isChanged) {
      setIsModalOpen(true);
      setIsShowAlert(false);
      return;
    }
    window.location.href = returnPath;
  };
  const closeModal = () => {
    setIsShowAlert(true);
    setIsModalOpen(false);
  };

  // ブラウザバックを検知して確認モーダルを表示する
  const ua = window.navigator.userAgent.toLowerCase();
  useEffect(() => {
    // 変更がない場合のほか、safariとiOSの場合（画面遷移後の動作に影響があるためブラウザバックを許容）は処理を抜ける
    if (
      ua.indexOf("iphone") > 0 ||
      ua.indexOf("ipod") > 0 ||
      (ua.indexOf("chrome") === -1 && ua.indexOf("safari") !== -1) ||
      !isChanged
    ) {
      return;
    }
    showAlertBeforeBrowserBack({ setIsShowAlert, setIsModalOpen });
  }, [isChanged, ua]);

  // 画面遷移を検知して確認アラートを表示する
  useEffect(() => {
    const showAlert = (event: BeforeUnloadEvent) => {
      if (!isChanged || !isShowAlert) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", showAlert);
    return () => window.removeEventListener("beforeunload", showAlert);
  }, [isChanged, isShowAlert]);

  // 送信処理
  const formEl = useRef<HTMLFormElement>(null);
  const onSubmit: SubmitHandler<InputProps> = (data) => {
    // 卒業年月と学校名のどちらかが未入力の場合はバリデーションエラー
    data.form_user_frontend_user_schools_update.user_schools_attributes.forEach(
      ({ school_name, graduated_year, graduated_month, will_graduate_flag }, index) => {
        if ((school_name || will_graduate_flag || graduated_month) && !graduated_year) {
          setError(`${formName}.${index}.graduated_year`, {
            type: "required",
            message: "卒業年を入力してください。",
          });
        }
        if ((school_name || graduated_year) && !graduated_month) {
          setError(`${formName}.${index}.graduated_month`, {
            type: "required",
            message: "卒業月を入力してください。",
          });
        }
        if ((graduated_year || graduated_month || will_graduate_flag) && !school_name) {
          setError(`${formName}.${index}.school_name`, {
            type: "required",
            message: "学校名を入力してください。",
          });
        }
      },
    );
    if (methods.formState.errors?.form_user_frontend_user_schools_update) {
      return;
    }
    setIsShowAlert(false);
    formEl.current?.submit();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form ref={formEl} action={action} onSubmit={methods.handleSubmit(onSubmit)} method="post">
          <input type="hidden" {...methods.register("authenticity_token")} />
          <input type="hidden" {...methods.register("_method")} />
          {fields.length > 0 &&
            fields.map((item, index) => (
              <SchoolLogsCassette
                cassetteIndex={index}
                graduatedYearList={graduatedYearList}
                graduatedMonthList={graduatedMonthList}
                remove={remove}
                key={item.id}
                errors={schools[index]?.errors}
              />
            ))}
          <StyledAdditionButtonOuter id="cassette-add">
            <AdditionButton onClick={appendCassette}>追加する</AdditionButton>
          </StyledAdditionButtonOuter>
          <SubmitAndCancelButtons handleOnClick={openModal} appOsType={appOsType} />
        </form>
      </FormProvider>
      <Suspense fallback={null}>
        {isModalOpen && (
          <CancelModal isModalOpen={isModalOpen} closeModal={closeModal} returnPath={returnPath} />
        )}
      </Suspense>
    </>
  );
};
