import { FC } from "react";
import { Link } from "@root/shared/components/Link";
import { Icon } from "@root/shared/components/Icon";
import * as styles from "./styles.css";

type Props = {
  url: string;
  text: string;
};

export const LineLink: FC<Props> = ({ url, text }) => {
  return (
    <div className={styles.wrapper}>
      <Link theme="line" href={url} target="_blank">
        <div className={styles.iconWrapper}>
          <Icon name="line" />
        </div>
        {text}
      </Link>
    </div>
  );
};
