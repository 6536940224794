import { FC } from "react";
import { FetchData } from "@root/utils/fetchData";
import { ButtonContentsProps } from "@legacy_user_frontend/components/projects/button_contents/src/button_contents_props";
import { useSalonTourButton } from "@userFrontend/hooks/useSalonTourButton";
import { Presentation } from "./Presentation";

type Props = {
  fetchUrl: string;
};

type FetchedData = {
  pageType: string;
  capitalizePageType: string;
  position: string;
  workCode: string;
  queryStr: string;
  isSp: boolean;
  isApp: boolean;
  // 求人・ブランド・企業詳細ではnumber、店舗詳細ではstringが返ってくるため
  clientShopId: number | string;
  gaLabel: string;
  title?: string;
  imgSrc: string;
  dummyImgPath: string;
  isPreview: boolean;
  isContractRunning: boolean;
  oiwaiContents: {
    isOiwaiDisplay: boolean;
    recruitmentRewardPrice: string;
    keepWorkRewardPrice: string;
  };
  considerationContents: {
    considerationDisplayFlag: boolean;
    considerationNumText: string;
  };
  buttonContents: {
    keepButtonContent: {
      keepButtonUrl: string;
      keepButtonQuery: string;
      hasKeeped: boolean;
      isShowKeepBalloon: boolean;
      isShowConfirmRemoveKeepModal: boolean;
    };
    scoutButtonContent: {
      scoutDeadline?: number;
      isScouted: boolean;
      scoutedEntryUrl?: string;
      scoutClientShopId?: number;
      isScoutExtend: boolean;
      extendScoutUrl: string;
      declineScoutUrl?: string;
    };
    entryButtonContent: {
      isEnterable: boolean;
      hasEntried: boolean;
      entryUrl: string;
    };
    salonTourButtonContent: {
      isSalonTour: boolean;
      salonTourEntryUrl: string;
    };
  };
  telNumberContent: {
    isShowTellButton: boolean;
    telNumber: string;
  };
  awardsLabel: {
    awardsLabelRank?: string;
    awardsLabelText?: string;
  };
  awards2022Label: {
    awardsLabelRank?: string;
    awardsLabelText?: string;
  };
  awards2023Label: {
    awardsLabelRank?: string;
    awardsLabelText?: string;
  };
  awards2024Label: {
    awardsLabelRank?: string;
    awardsLabelText?: string;
  };
  salaryContents: [
    {
      employmentTypeName: string;
      salaryTypeName: string;
      minPrice: string;
      minUnit: string;
      maxPrice: string;
      maxUnit: string;
      isEllipsisDisplay: boolean;
    },
  ];
  images:
    | [
        {
          imageUrl: string;
          key: string;
        },
      ]
    | null;
};

const btnPositionControllingClassName = (position: string): string => {
  switch (position) {
    case "middle":
      return "";
    case "top":
      return "js-footer-fix__first-point";
    default:
      return "js-footer-fix__last-point";
  }
};

const getFirstValidAwardLabel = (
  awards2024Label: ButtonContentsProps["awards2024Label"],
  awards2023Label: ButtonContentsProps["awards2023Label"],
  awards2022Label: ButtonContentsProps["awards2022Label"],
  awardsLabel: ButtonContentsProps["awardsLabel"],
): ButtonContentsProps["awardsLabel"] | undefined => {
  const allLabels = [awards2024Label, awards2023Label, awards2022Label, awardsLabel];
  return allLabels.find((label) => Boolean(label?.awardsLabelRank && label?.awardsLabelText));
};

export const ButtonContents: FC<Props> = ({ fetchUrl }) => {
  const { handleClickSalonTourButton } = useSalonTourButton();
  const data = FetchData<{ data: FetchedData }>(fetchUrl);

  // ロード中と保存済みの検索条件がない場合は何も表示しない
  if (!data) return null;

  const {
    pageType,
    capitalizePageType,
    position,
    workCode,
    queryStr,
    isSp,
    isApp,
    clientShopId,
    gaLabel,
    title,
    imgSrc,
    dummyImgPath,
    isPreview,
    isContractRunning,
    oiwaiContents,
    considerationContents,
    buttonContents,
    telNumberContent,
    awardsLabel,
    awards2022Label,
    awards2023Label,
    awards2024Label,
    salaryContents,
    images,
  } = data.data;
  const awardsLabelContent = getFirstValidAwardLabel(
    awards2024Label,
    awards2023Label,
    awards2022Label,
    awardsLabel,
  );
  const scoutGaLabel = `${workCode}_${buttonContents.scoutButtonContent.scoutClientShopId}`;
  const dataStyleColumn =
    (pageType !== "detail" && !imgSrc) || position === "middle" || position === "bottom" ? 1 : 2;

  return (
    <Presentation
      pageType={pageType}
      capitalizePageType={capitalizePageType}
      position={position}
      workCode={workCode}
      queryStr={queryStr}
      isSp={isSp}
      isApp={isApp}
      clientShopId={clientShopId}
      gaLabel={gaLabel}
      title={title}
      imgSrc={imgSrc}
      dummyImgPath={dummyImgPath}
      isPreview={isPreview}
      isContractRunning={isContractRunning}
      oiwaiContents={oiwaiContents}
      considerationContents={considerationContents}
      buttonContents={buttonContents}
      telNumberContent={telNumberContent}
      awardsLabelContent={awardsLabelContent}
      salaryContents={salaryContents}
      images={images}
      btnPositionControllingClassName={btnPositionControllingClassName(position)}
      handleClickSalonTourButton={handleClickSalonTourButton}
      scoutGaLabel={scoutGaLabel}
      dataStyleColumn={dataStyleColumn}
    />
  );
};
