import { FC, ComponentProps } from "react";
import {
  Salaries,
  SalariesProps,
} from "@legacy_user_frontend/components/projects/button_contents/src/top_contents/src/salaries";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import {
  StyledImageContents,
  StyledTitleText,
  StyledAwardsLabels,
  StyledAwardsLabel,
} from "@legacy_user_frontend/components/projects/button_contents/src/styled_elements";
import { ConsiderationNumInformation } from "@userFrontend/features/publication/components/ConsiderationNumInformation";
import { WorkImageSlider } from "@userFrontend/features/publication/components/WorkImageSlider";

export type TopContentsProps = {
  isSp: boolean;
  pageType: string;
  images: ComponentProps<typeof WorkImageSlider>["images"] | null;
  imgSrc: string;
  dummyImgPath: string;
  isContractRunning: boolean;
  awardsLabel?: {
    awardsLabelRank?: string;
    awardsLabelText?: string;
  };
  considerationContents: {
    considerationDisplayFlag: boolean;
    considerationNumText: string;
  };
  salaryContents: SalariesProps["salaryContents"];
  title?: string;
  workCode: string;
  isEnterable: boolean;
};

export const TopContents: FC<TopContentsProps> = ({
  isSp,
  pageType,
  images,
  imgSrc,
  dummyImgPath,
  isContractRunning,
  awardsLabel,
  considerationContents: { considerationDisplayFlag, considerationNumText },
  salaryContents,
  title,
  workCode,
  isEnterable,
}) => {
  return (
    <>
      {pageType === "detail" && (
        <>
          {images && isContractRunning && (
            <WorkImageSlider images={images} pcWidthStyle={!isEnterable ? "wide" : "narrow"} />
          )}
        </>
      )}
      {pageType !== "detail" && imgSrc && isContractRunning && (
        <StyledImageContents is1column={!workCode}>
          <LazyLoadImage src={imgSrc} dummyImgPath={dummyImgPath} alt="" width={657} height={370} />
        </StyledImageContents>
      )}
      {isSp && workCode && pageType === "detail" && (
        <>
          {awardsLabel &&
            awardsLabel?.awardsLabelRank &&
            awardsLabel?.awardsLabelText &&
            pageType === "detail" && (
              <StyledAwardsLabels>
                <StyledAwardsLabel data-rank={awardsLabel.awardsLabelRank}>
                  {awardsLabel.awardsLabelText}
                </StyledAwardsLabel>
              </StyledAwardsLabels>
            )}
          {considerationDisplayFlag && isEnterable && (
            // isSpの場合(TAB,SP想定)表示される
            <div style={{ margin: "16px 0" }}>
              <ConsiderationNumInformation considerationNumText={considerationNumText} />
            </div>
          )}
          {title && <StyledTitleText>{title}</StyledTitleText>}
          {isContractRunning && <Salaries salaryContents={salaryContents} />}
        </>
      )}
    </>
  );
};
