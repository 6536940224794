import { FC } from "react";
import { Link } from "@root/shared/components/Link";

type Props = {
  text: string;
  className?: string;
  // firebaseActionはwebviewでfirebaseイベント送信のために使用する。classNameに.firebase-clickがある場合にのみ機能する。
  firebaseAction?: string;
  url: string;
  onClick?: () => void;
  gaAction?: string;
  gaLabel?: string;
};

export const EntryLink: FC<Props> = ({
  text,
  className,
  firebaseAction,
  url,
  onClick,
  gaAction,
  gaLabel,
}) => {
  return (
    <Link
      theme="primary"
      className={className}
      href={url}
      onClick={onClick}
      data-ga-action={gaAction}
      data-ga-label={gaLabel}
      {...(firebaseAction
        ? {
            "data-firebase-action": firebaseAction,
            "data-firebase-view-type": "screen_view",
          }
        : {})}
    >
      {text}
    </Link>
  );
};
