import { FC } from "react";
import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

const Heading = styled.h2`
  font-size: 14px;
  color: ${Colors.DEEP_BLACK};
  line-height: 1.5;
  font-weight: bold;

  span {
    font-weight: normal;
  }

  ${Breakpoints.MEDIUM} {
    font-size: 16px;
    line-height: 1.3;
  }
`;

export interface HeaderProps {
  shopOrBrandName: string;
  businessTypeAndOccupation: string;
}

export const Header: FC<HeaderProps> = ({ shopOrBrandName, businessTypeAndOccupation }) => (
  <Heading>
    {shopOrBrandName}│<span>{businessTypeAndOccupation}</span>
  </Heading>
);
