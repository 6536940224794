import { FC } from "react";
import { Button } from "@root/shared/components/Button";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import * as styles from "./styles.css";

type Props = {
  operatorAppealFlag?: boolean;
  gaLabel?: string;
  gaAction?: string;
  onClick?: () => void;
};

export const LineConsultButton: FC<Props> = ({
  operatorAppealFlag = true,
  gaLabel,
  gaAction,
  onClick,
}) => (
  <>
    {operatorAppealFlag && (
      <div className={styles.appealWrapper}>
        ＼<p className={styles.appealText}>オペレーターが今すぐ対応</p>／
      </div>
    )}
    <Button
      theme="lineWhite"
      onClick={onClick}
      data-ga-label={gaLabel}
      data-ga-action={gaAction}
      className={styles.buttonWrapper}
    >
      <div className={styles.logoWrapper}>
        <LazyLoadImage src="/images/shared/LINE_Brand_icon.png" height={24} width={24} />
      </div>
      <p className={styles.buttonTextWrapper}>求人について質問・相談する</p>
    </Button>
  </>
);
