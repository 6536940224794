/* eslint-disable jsx-a11y/label-has-associated-control */
import { FC, useEffect, useState, ChangeEvent } from "react";
import { DirectUploader } from "@legacy_root/utils";
import {
  ImageMimeTypePattern,
  ImageUploaderProps,
} from "@legacy_user_frontend/components/pages/mypage/profiles/product_photo_edit/src/product_photo_edit_props";
import {
  StyledLabel,
  StyledInput,
  StyledIndicatorOverlay,
  StyledLoadingSpinner,
  StyledLoadingSpinnerText,
} from "@legacy_user_frontend/components/pages/mypage/profiles/product_photo_edit/src/styled_elements";

declare const newrelic: any; // eslint-disable-line @typescript-eslint/no-explicit-any

const imageMimeTypePatterns: ImageMimeTypePattern[] = [
  {
    mime: "image/png",
    pattern: [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a],
  },
  {
    mime: "image/jpeg",
    pattern: [0xff, 0xd8, 0xff],
  },
];

export const ImageUploader: FC<ImageUploaderProps> = ({
  uploadPath,
  imageLength,
  appendFields,
  validation,
}) => {
  const { maxCount } = validation.productPhotos;
  const { maxByte, minByte } = validation.image;
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [uploadImages, setUploadImages] = useState<{
    images: string;
    imageUrl: string;
  } | null>(null);
  const [isShowIndicator, setIsShowIndicator] = useState(false);

  // 画像のアップロード処理
  const directUpload = async (file: File) => {
    const uploader = new DirectUploader(uploadPath, file);
    try {
      const blob = await uploader.upload();
      setUploadImages({ images: blob.signed_id, imageUrl: URL.createObjectURL(file) });
    } catch (error) {
      setErrorMessage([...errorMessage, "アップロードに失敗しました。"]);

      if (typeof newrelic !== "undefined") {
        newrelic.noticeError(error);
      }
    }
  };

  // ファイルサイズをチェックしてbooleanを返す
  const validateAndReturnErrorMessage = (file: File): string | null => {
    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      return "形式が異なるため、アップロードできなかった画像がありました。";
    }
    if (file.size > maxByte) {
      return "サイズが大きいため、アップロードできなかった画像がありました。";
    }
    if (file.size < minByte) {
      return "不正なサイズのため、アップロードできなかった画像がありました。";
    }
    return null;
  };

  // バリデーションチェックを行い、通ったもののみアップロードする
  const uploadAndReturnErrorMessages = async (files: File[]) => {
    let validationPassedFilesLength = 0;
    const errorMessages: string[] = [];
    const maxCountErrorMessage = `写真が多すぎるため、アップロードできなかった画像がありました。追加できる写真は最大${maxCount}枚です。`;
    await Promise.all(
      files.map(async (file) => {
        // アップロード数が上限を超えた場合はエラーにする
        if (imageLength + validationPassedFilesLength + 1 > maxCount) {
          // まだエラーメッセージを出していない場合は出す
          if (!errorMessages.find((message) => message === maxCountErrorMessage)) {
            errorMessages.push(maxCountErrorMessage);
          }
          return;
        }
        // ファイルサイズが適正でない場合はアップロードしない
        const imageSizeValidateErrorMessage = validateAndReturnErrorMessage(file);
        if (imageSizeValidateErrorMessage) {
          errorMessages.push(imageSizeValidateErrorMessage);
          return;
        }

        // 拡張子を書き換えられてアップロードされるパターンに対応するため、
        // ファイルヘッダーのバイトをチェックする
        // 参考: https://thundermiracle.com/blog/2021-07-11-how-to-check-uploaded-image-is-valid/#%E6%96%B9%E6%B3%952-image-type-pattern%E3%81%A7%E5%88%A4%E5%88%A5
        const validBytePattern = await validateByPattern(file);
        if (!validBytePattern) {
          errorMessages.push(
            "無効なファイルタイプのため、アップロードできなかった画像がありました。",
          );
          return;
        }
        validationPassedFilesLength += 1;
        await directUpload(file);
      }),
    );
    return errorMessages;
  };

  const getMimeTypeByBytes = (bytes: Uint8Array): string => {
    const matched = imageMimeTypePatterns.find(({ pattern }) =>
      // skip if pattern is null as Pattern Mask is 0x00
      pattern.every((p, ind) => p == null || bytes[ind] === p),
    );

    return matched?.mime || "unknown type";
  };

  const minFileHeadBytesCount = Math.max(
    ...imageMimeTypePatterns.map(({ pattern }) => pattern.length),
  );

  const validateByPattern = async (file: File): Promise<boolean> => {
    let fileHeadBytes = await new Promise<Uint8Array>((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target != null && e.target.result != null && typeof e.target.result !== "string")
          fileHeadBytes = new Uint8Array(e.target.result);
        resolve(fileHeadBytes);
      };
      // only read necessary header
      reader.readAsArrayBuffer(file.slice(0, minFileHeadBytesCount));
    });

    const mimeType = getMimeTypeByBytes(fileHeadBytes);

    return mimeType !== "unknown type";
  };

  // アップロードボタン押下でバリデーションチェックしアップロード処理を実行
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadFilesList = event.target.files;
    if (!uploadFilesList) {
      return;
    }
    const uploadFiles = Array.from(uploadFilesList);
    setIsShowIndicator(true);
    const errorMessageArray: string[] = [];

    uploadAndReturnErrorMessages(uploadFiles).then((errorMessages) => {
      // アップロード処理完了後にインジケーターを閉じる
      setIsShowIndicator(false);
      if (errorMessages.length) {
        setErrorMessage(errorMessages);
        errorMessageArray.concat(errorMessages);
      } else {
        setErrorMessage([]);
      }
    });

    // エラーがある場合はアラートを表示し、なければリセットする
    if (errorMessageArray.length) {
      setErrorMessage(errorMessageArray);
    } else {
      setErrorMessage([]);
    }

    const ev = event;
    ev.target.value = "";
  };

  // アップロードした画像をfieldArrayに反映
  useEffect(() => {
    if (!uploadImages) {
      return;
    }
    appendFields(uploadImages.images, uploadImages.imageUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadImages]); // 無限ループを避けるためuploadImagesのみを指定する

  // アップロード時にエラーがあった場合はアラートを表示する
  useEffect(() => {
    if (!errorMessage.length) {
      return;
    }
    alert(errorMessage.join("\n"));
  }, [errorMessage]);

  return (
    <>
      <StyledLabel htmlFor="upload-image">画像を追加する</StyledLabel>
      <StyledInput
        id="upload-image"
        type="file"
        accept="image/png, image/jpeg"
        onChange={handleChange}
        multiple
      />
      <StyledIndicatorOverlay isShow={isShowIndicator}>
        <StyledLoadingSpinner />
        <StyledLoadingSpinnerText>
          ただいま処理中です。暫くお待ちください。
        </StyledLoadingSpinnerText>
      </StyledIndicatorOverlay>
    </>
  );
};
