import { FC } from "react";
import { Presentation } from "./Presentation";
import { useWorkDetailBottomButtons } from "./useWorkDetailBottomButtons";

type Props = {
  fetchUrl: string;
  recommendFetchUrl: string;
  createLineConsultButtonHistoryUrl: string;
  isShowLineAppealText: boolean;
};

export const WorkDetailBottomButtons: FC<Props> = ({
  fetchUrl,
  recommendFetchUrl,
  createLineConsultButtonHistoryUrl,
  isShowLineAppealText,
}) => {
  const formattedData = useWorkDetailBottomButtons(
    fetchUrl,
    recommendFetchUrl,
    createLineConsultButtonHistoryUrl,
    isShowLineAppealText,
  );
  return formattedData ? <Presentation {...formattedData} /> : null;
};
