import { FC, useState } from "react";
import { ModalContentsProps } from "@legacy_user_frontend/components/pages/jobs/job_search_navigation/src/search_modal/src/search_modal_props";
import { useProvideModal, ModalContext } from "./src/modules";
import { StyledSearchNavigationContainer } from "./src/styled_elements";
import { SearchModal } from "./src/search_modal";
import { SearchNavigation } from "./src/search_navigation";

declare const newrelic: any; // eslint-disable-line @typescript-eslint/no-explicit-any

export type JobSearchNavigationProps = {
  gaAction: string;
  dataForNav: {
    [key: string]: {
      [key: string]: string | number | boolean;
      /* 定義しないと代入箇所で怒られるものは個別に定義 */
      id: number;
      search_path: string; // eslint-disable-line camelcase
      is_number_displayed: boolean; // eslint-disable-line camelcase
    }[];
  };
  searchModalDataFetchUrl: string | null;
  numberOfSearchResultsUrl: string;
};

export const OUTPUT_NAV_NAME: { [key: string]: string } = {
  prefecture: "都道府県",
  city: "市区町村",
  station: "駅",
  employment: "雇用形態",
  business_type: "職種",
};

export const JobSearchNavigation: FC<JobSearchNavigationProps> = ({
  gaAction,
  dataForNav,
  searchModalDataFetchUrl,
  numberOfSearchResultsUrl,
}) => {
  const { isOpen, setIsOpen, modalType, setModalType, openModal, closeModal } = useProvideModal();

  const [modalContents, setModalContents] = useState<ModalContentsProps["modalContents"]>({
    searchUrl: "",
    businessTypeContents: [],
    prefectureContents: [],
    cityContents: [],
    stationContents: [],
  });

  const [isAccordionOpen, setIsAccordionOpen] = useState({});

  const [totalCount, setTotalCount] = useState(0);
  const { cityContents, businessTypeContents, prefectureContents, stationContents } = modalContents;

  // モーダル内のフォームで使用するデータを取得
  if (
    searchModalDataFetchUrl &&
    isOpen &&
    !(
      cityContents?.length ||
      businessTypeContents?.length ||
      prefectureContents?.length ||
      stationContents?.length
    )
  ) {
    (async () => {
      try {
        const res = await fetch(searchModalDataFetchUrl);
        const json = await res.json();
        setModalContents(json);
      } catch (e) {
        if (typeof newrelic !== "undefined") {
          newrelic.noticeError(e);
        }
      }
    })();
  }

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        setIsOpen,
        modalType,
        setModalType,
        openModal,
        closeModal,
      }}
    >
      {isOpen && searchModalDataFetchUrl && (
        <SearchModal
          title={OUTPUT_NAV_NAME[modalType]}
          numberOfSearchResultsUrl={numberOfSearchResultsUrl}
          modalContents={modalContents}
          isAccordionOpen={isAccordionOpen}
          setIsAccordionOpen={setIsAccordionOpen}
          totalCount={totalCount}
          setTotalCount={setTotalCount}
        />
      )}
      <div>
        {Object.entries(dataForNav)
          .map(([key, value]) => ({ key, value }))
          .map((navListGroup) => {
            const moreSearchButtonDisplayed = !!(
              searchModalDataFetchUrl && navListGroup.key !== "employment"
            );
            return (
              <StyledSearchNavigationContainer key={navListGroup.key}>
                <SearchNavigation
                  navListGroup={navListGroup}
                  gaAction={gaAction}
                  moreSearchButtonDisplayed={moreSearchButtonDisplayed}
                />
              </StyledSearchNavigationContainer>
            );
          })}
      </div>
    </ModalContext.Provider>
  );
};
