import {
  useEffect,
  useState,
  ReactNode,
  ReactElement,
  Children,
  isValidElement,
  useMemo,
} from "react";

type Props = {
  testId: string;
  children: ReactNode;
};

export const ABTestRenderer = ({ testId, children }: Props): JSX.Element | null => {
  const [variantId, setVariantId] = useState(() => window.localStorage.getItem(testId));
  const variants = useMemo(
    () =>
      Children.toArray(children).filter((child): child is ReactElement<VariantProps> =>
        isValidElement<VariantProps>(child),
      ),
    [children],
  );

  useEffect(() => {
    if (!variantId) {
      const variantIds = variants.map((variant) => variant.props.id);
      const newVariantId = getRandomVariantId(variantIds);
      setVariantId(newVariantId);
      window.localStorage.setItem(testId, newVariantId);
    }
  }, [testId, children, variantId, variants]);

  return variants.find((variant) => variant.props.id === variantId) ?? null;
};

type VariantProps = {
  id: string; // eslint-disable-line react/no-unused-prop-types
  children: ReactNode;
};

export const Variant = ({ children }: VariantProps): JSX.Element => {
  return <>{children}</>;
};

const getRandomVariantId = (variantIds: string[]): string => {
  const randomIndex = Math.floor(Math.random() * variantIds.length);
  return variantIds[randomIndex];
};
