import { FC } from "react";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import { USER_REWARD_NAME } from "@root/utils/constants";

interface Props {
  url: string;
  modalPath: string;
  gaAction: string;
  gaLabel: string;
  lazyLoadImage: {
    imagesDirectory: string;
    spWidth: number;
    spHeight: number;
    pcWidth: number;
    pcHeight: number;
    dummyImgPath: string;
  };
}

export const OiwaiBanner: FC<Props> = ({
  url,
  modalPath,
  gaAction,
  gaLabel,
  lazyLoadImage: { imagesDirectory, spWidth, spHeight, pcWidth, pcHeight, dummyImgPath },
}) => (
  <aside className="p-banner--horizontally-long">
    <a
      href={url}
      className="u-show-only-pc ga-click"
      data-ga-action={gaAction}
      data-ga-label={gaLabel}
    >
      <LazyLoadImage
        src={`${imagesDirectory}/images/user_frontend/pc/campaign/w-oiwai--header-bn-wide.png`}
        dummyImgPath={dummyImgPath}
        alt={`対象者全員 入社後30日・入社後1年 2回もらえる${USER_REWARD_NAME}`}
        width={pcWidth}
        height={pcHeight}
      />
    </a>
    <span
      className="u-show-only-sp js-open-oiwai-modal ga-click"
      data-href={modalPath}
      data-ga-action={gaAction}
      data-ga-label={gaLabel}
    >
      <LazyLoadImage
        src={`${imagesDirectory}/images/user_frontend/sp/campaign/w-oiwai--header-bn.png`}
        dummyImgPath={dummyImgPath}
        alt={`対象者全員 入社後30日・1年 2回もらえる${USER_REWARD_NAME}`}
        width={spWidth}
        height={spHeight}
      />
    </span>
  </aside>
);
