import { useState, useCallback, useEffect } from "react";
import { sendGaEvent } from "@root/utils/sendGaEvent";
import { getMobileAppState } from "@root/utils";
import { ANDROID_APP_ID, IOS_APP_ID } from "@root/utils/constants";

declare const webkit: any; // eslint-disable-line @typescript-eslint/no-explicit-any
declare const Android: any; // eslint-disable-line @typescript-eslint/no-explicit-any
const mobileApp = getMobileAppState();

export const useSalonTourButton = (): {
  handleClickSalonTourButton: (
    isApp: boolean,
    workCode: string,
    isScouted: boolean,
    clientShopId?: string | number,
    gaAction?: string,
    gaLabel?: string,
    salonTourEntryUrl?: string,
  ) => void;
} => {
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    const resetClickFlag = () => {
      setIsClicked(false);
    };

    window.addEventListener("pageshow", resetClickFlag);

    return () => {
      window.removeEventListener("pageshow", resetClickFlag);
    };
  }, []);

  const handleClickSalonTourButton = useCallback(
    (
      isApp: boolean,
      workCode: string,
      isScouted: boolean,
      clientShopId?: string | number,
      gaAction?: string,
      gaLabel?: string,
      salonTourEntryUrl?: string,
    ) => {
      const eventData = {
        message: "tour_entry",
        work_code: workCode,
        client_shop_id: clientShopId,
        scout_flag: isScouted,
      };

      if (isApp) {
        if (mobileApp === IOS_APP_ID) {
          // iOS
          return webkit && webkit.messageHandlers.callbackHandler.postMessage(eventData);
        }
        if (mobileApp === ANDROID_APP_ID) {
          // Android
          return Android && Android.postMessage("TourEntry", JSON.stringify(eventData));
        }
        return null;
      }

      if (!isClicked) {
        setIsClicked(true);
        if (salonTourEntryUrl) {
          window.location.href = salonTourEntryUrl;
        }
        if (gaAction && gaLabel) {
          sendGaEvent({ category: "Click", action: gaAction, label: gaLabel });
        }
      }
      return null;
    },
    [isClicked],
  );
  return { handleClickSalonTourButton };
};
