import { ComponentProps, FC } from "react";
import styled from "styled-components";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import { Colors } from "@legacy_user_frontend/utils/colors";

interface Props {
  appDlUrl: string;
  gaAction: string;
  gaLabel: string;
  image: ImageProps;
}

type ImageProps = ComponentProps<typeof LazyLoadImage>;

const LinkPanel = styled.a`
  display: block;
  border: 1px solid ${Colors.APP_PROMOTION_BANNER_PINK};
  border-radius: 7.6%;
`;

export const RegistrationCompleteAppBanner: FC<Props> = ({
  appDlUrl,
  gaAction,
  gaLabel,
  image: { src, dummyImgPath, alt, width, height },
}) => (
  <LinkPanel
    href={appDlUrl}
    className="ga-click js-deferred-deep-link"
    data-ga-action={gaAction}
    data-ga-label={gaLabel}
    data-is-login="true" // 会員登録画面なので常にtrueになる
  >
    <LazyLoadImage src={src} dummyImgPath={dummyImgPath} alt={alt} width={width} height={height} />
  </LinkPanel>
);
