import { FC } from "react";

export interface SalariesProps {
  salaries: {
    employmentTypeName: string;
    salaryTypeName: string;
    minPrice: string;
    minUnit: string;
    maxPrice: string;
    maxUnit: string;
  }[];
}

export const Salaries: FC<SalariesProps> = ({ salaries }) => (
  <>
    {salaries.map(
      ({ employmentTypeName, salaryTypeName, minPrice, minUnit, maxPrice, maxUnit }) => (
        <p key={`${employmentTypeName}_${salaryTypeName}`}>
          {employmentTypeName} / {salaryTypeName}
          {(minPrice || maxPrice) && (
            <>
              <b>{minPrice}</b>
              {minUnit} 〜 <b>{maxPrice}</b>
              {maxUnit}
            </>
          )}
        </p>
      ),
    )}
  </>
);
