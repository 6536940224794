import { FC, ComponentProps } from "react";
import { DetailPageActionButtons } from "@userFrontend/features/publication/components/DetailPageActionButtons";

type Props = ComponentProps<typeof DetailPageActionButtons>;

export const Buttons: FC<Props> = ({
  workCode,
  clientShopId,
  isApp,
  isSp,
  verticallyStyle,
  isPreview,
  capitalizePageType,
  oiwaiInfomationProps,
  entryProps,
  keepProps,
  salonTourProps,
  telEntryProps,
  scoutProps,
  extendScoutProps,
  declineScoutProps,
  lineProps,
}) => {
  return (
    <DetailPageActionButtons
      verticallyStyle={verticallyStyle}
      workCode={workCode}
      clientShopId={clientShopId}
      isApp={isApp}
      isSp={isSp}
      isPreview={isPreview}
      capitalizePageType={capitalizePageType}
      oiwaiInfomationProps={oiwaiInfomationProps}
      entryProps={entryProps}
      keepProps={keepProps}
      salonTourProps={salonTourProps}
      telEntryProps={telEntryProps}
      scoutProps={scoutProps}
      extendScoutProps={extendScoutProps}
      declineScoutProps={declineScoutProps}
      lineProps={lineProps}
    />
  );
};
