import { FC, lazy, Suspense, useState } from "react";
import type { Settings } from "react-slick";
import * as styles from "./styles.css";

type Image = {
  key: string;
  imageUrl: string;
};

type Props = {
  // 全ての画面幅において、カルーセル画像を画面幅
  pcWidthStyle: "narrow" | "wide";
  images: Image[];
};

const Slider = lazy(() => import("react-slick"));

export const WorkImageSlider: FC<Props> = ({ pcWidthStyle = "narrow", images }) => {
  const [count, setCount] = useState(1);
  const settings: Settings = {
    lazyLoad: "ondemand",
    infinite: false,
    arrows: true,
    autoplay: false,
    pauseOnFocus: true,
    dots: true,
    centerMode: false,
    centerPadding: "0",
    draggable: true,
    speed: 300,
    touchThreshold: 10,
    afterChange: (index) => {
      setCount(index + 1);
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className={styles.wrapper[pcWidthStyle]}>
      <div className={styles.counter}>
        {count}/{images.length}
      </div>
      <div className={styles.sliderWrapper}>
        <Suspense fallback={<div>Loading...</div>}>
          <div className={styles.sliderContent}>
            <Slider {...settings}>
              {images.map((item: Image) => (
                <div key={item.key} className={styles.imageWrapper}>
                  <img src={item.imageUrl} alt="" className={styles.image} />
                </div>
              ))}
            </Slider>
          </div>
        </Suspense>
      </div>
    </div>
  );
};
