import { FC } from "react";
import styled from "styled-components";
import { Colors } from "@legacy_user_frontend/utils";
import { UnstyledButton } from "@legacy_user_frontend/components/ui_parts/buttons/unstyled_button";

type ButtonProps = JSX.IntrinsicElements["button"];

const StyledButton = styled(UnstyledButton)`
  display: inline;
  background-color: transparent;
  color: ${Colors.LINK};

  &:hover {
    text-decoration: underline;
  }
`;

export const TextLinkButton: FC<ButtonProps> = ({ type, className, onClick, children }) => (
  <StyledButton type={type} onClick={onClick} className={className}>
    {children}
  </StyledButton>
);
