import { FC } from "react";
import styled from "styled-components";
import { TextLinkButton } from "@legacy_user_frontend/components/ui_parts";

type ButtonProps = JSX.IntrinsicElements["button"];

const StyledButton = styled(TextLinkButton)`
  display: inline-flex;
  align-items: center;

  &::after {
    content: "";
    display: inline-block;
    width: 13px;
    height: 12px;
    margin-left: 4px;
    background-image: url("/images/user_frontend/global/icon/icn_trashbox_blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

export const DeleteTextLinkButton: FC<ButtonProps> = ({ type, className, onClick, children }) => (
  <StyledButton type={type} onClick={onClick} className={className}>
    {children}
  </StyledButton>
);
