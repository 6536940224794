/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, Suspense, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, SubmitHandler, useForm, useWatch } from "react-hook-form";
import CancelModal from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/cancel_modal";
import { SubmitAndCancelButtons } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/submit_and_cancel_buttons";
import { BoxWithShadow } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/box_with_shadow";
import { DefinitionList } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/definition_list";
import {
  Pulldown,
  TextArea,
  ValidationErrorMessage,
} from "@legacy_user_frontend/components/ui_parts";
import { ToSnakeCase } from "@legacy_root/utils/common";
import { showAlertBeforeBrowserBack } from "@legacy_root/user_frontend/components/pages/mypage/profiles/utils/show_alert_before_browser_back";
import { Checkbox } from "@root/shared/components/Checkbox";
import { ContactableTimeRangeEditProps, InputProps } from "./src/contactable_time_range_edit_props";
import {
  StyledErrorMessageOuter,
  StyledLabel,
  StyledList,
  StyledOtherTimeRangeHeading,
  StyledOtherTimeRangeSelectOuter,
} from "./src/styled_elements";

export const ContactableTimeRangeEdit: FC<ContactableTimeRangeEditProps> = ({
  action,
  timeRangeIds,
  timeRangesStart,
  timeRangesEnd,
  contactableTimeRangesNote,
  timeRangesList,
  timeRangesStartList,
  timeRangesEndList,
  errors,
  returnPath,
  appOsType,
}) => {
  // 初期値の設定
  const timeRangeIdsString = useMemo(() => timeRangeIds.map((id) => `${id}`), [timeRangeIds]);
  const defaultValues = useMemo(
    () => ({
      time_ranges_start: timeRangesStart,
      time_ranges_end: timeRangesEnd,
      contactable_time_ranges_note: contactableTimeRangesNote,
      time_range_ids: timeRangeIdsString,
    }),
    [contactableTimeRangesNote, timeRangeIdsString, timeRangesEnd, timeRangesStart],
  );
  const methods = useForm<InputProps>({
    defaultValues: {
      authenticity_token: "",
      _method: "put",
      form_user_frontend_contactable_time_range_update: defaultValues,
    },
  });

  // サーバー側から渡ってきたエラーメッセージを項目に反映する
  const validateErrors = methods.formState.errors;
  useEffect(() => {
    const setErrorMessage = (key: string) => {
      const errorFormName = ToSnakeCase(
        `form_user_frontend_contactable_time_range_update.${key}`,
      ) as keyof InputProps;
      methods.setError(errorFormName, {
        type: "manual",
        message: errors[key],
      });
    };
    if (Object.keys(validateErrors).length === 0) {
      Object.keys(errors).map((key) => setErrorMessage(key));
    }
  }, [errors, methods, validateErrors]);

  // 時間指定なしを選択した場合は他のチェックを外し、その他の時間を選択プルダウンは選択を解除して非活性にする
  const unspecifiedValue = 9;
  const [checkedTimeLine, setCheckedTimeLine] = useState(timeRangeIdsString);
  const [isPulldownDisabled, setIsPulldownDisabled] = useState(
    timeRangeIds.includes(unspecifiedValue),
  );
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (event.target.value === `${unspecifiedValue}`) {
        setCheckedTimeLine([event.target.value]);
        methods.setValue("form_user_frontend_contactable_time_range_update.time_ranges_start", "");
        methods.setValue("form_user_frontend_contactable_time_range_update.time_ranges_end", "");
        setIsPulldownDisabled(true);
      } else {
        const filteredTimeLine = checkedTimeLine.filter(
          (timeLineValue) => timeLineValue !== `${unspecifiedValue}`,
        );
        filteredTimeLine.push(event.target.value);
        setCheckedTimeLine(filteredTimeLine);
        setIsPulldownDisabled(false);
      }
    } else {
      setCheckedTimeLine(
        checkedTimeLine.filter((timeLineValue) => timeLineValue !== event.target.value),
      );
      setIsPulldownDisabled(false);
    }
    methods.setValue(
      "form_user_frontend_contactable_time_range_update.time_range_ids",
      checkedTimeLine,
    );
  };

  // 変更の検出
  const watchedValues = useWatch({
    control: methods.control,
    name: "form_user_frontend_contactable_time_range_update",
  });
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    if (JSON.stringify(watchedValues) !== JSON.stringify(defaultValues)) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [watchedValues, defaultValues]);

  // キャンセルボタン押下時に確認モーダルを表示する
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    if (isChanged) {
      setIsModalOpen(true);
      setIsShowAlert(false);
      return;
    }
    window.location.href = returnPath;
  };
  const closeModal = () => {
    setIsShowAlert(true);
    setIsModalOpen(false);
  };

  // ブラウザバックを検知して確認モーダルを表示する
  const ua = window.navigator.userAgent.toLowerCase();
  useEffect(() => {
    // 変更がない場合のほか、safariとiOSの場合（画面遷移後の動作に影響があるためブラウザバックを許容）は処理を抜ける
    if (
      ua.indexOf("iphone") > 0 ||
      ua.indexOf("ipod") > 0 ||
      (ua.indexOf("chrome") === -1 && ua.indexOf("safari") !== -1) ||
      !isChanged
    ) {
      return;
    }
    showAlertBeforeBrowserBack({ setIsShowAlert, setIsModalOpen });
  }, [isChanged, ua]);

  // 画面遷移を検知して確認アラートを表示する
  useEffect(() => {
    const showAlert = (event: BeforeUnloadEvent) => {
      if (!isChanged || !isShowAlert) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", showAlert);
    return () => window.removeEventListener("beforeunload", showAlert);
  }, [isChanged, isShowAlert]);

  // 送信処理
  const formEl = useRef<HTMLFormElement>(null);
  const onSubmit: SubmitHandler<InputProps> = (data) => {
    const timeRangesStartValue =
      data.form_user_frontend_contactable_time_range_update.time_ranges_start;
    const timeRangesEndValue =
      data.form_user_frontend_contactable_time_range_update.time_ranges_end;

    // 時間が指定されていない場合のバリデーションエラー
    if (!timeRangesStartValue && timeRangesEndValue) {
      // 開始時間のみ指定されていない場合
      methods.setError("form_user_frontend_contactable_time_range_update.time_ranges_start", {
        type: "required",
        message: "開始時間を選択してください",
      });
      return;
    }
    if (timeRangesStartValue && !timeRangesEndValue) {
      // 終了時間のみ指定されていない場合
      methods.setError("form_user_frontend_contactable_time_range_update.time_ranges_end", {
        type: "required",
        message: "終了時間を選択してください",
      });
      return;
    }
    setIsShowAlert(false);
    formEl.current?.submit();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form ref={formEl} action={action} onSubmit={methods.handleSubmit(onSubmit)} method="post">
          <input type="hidden" {...methods.register("authenticity_token")} />
          <input type="hidden" {...methods.register("_method")} />
          <BoxWithShadow>
            <DefinitionList>
              <dt>連絡可能時間帯</dt>
              <dd>
                <StyledList>
                  {timeRangesList.map(({ key, value }) => (
                    <li key={key}>
                      <input
                        type="hidden"
                        name="form_user_frontend_contactable_time_range_update[time_range_ids][]"
                        value=""
                      />
                      <Checkbox
                        name="form_user_frontend_contactable_time_range_update[time_range_ids][]"
                        id={`js_contactable_time_range_ids_${value}`}
                        value={`${value}`}
                        checked={checkedTimeLine.includes(`${value}`)}
                        onChange={handleOnChange}
                      />
                      <StyledLabel htmlFor={`js_contactable_time_range_ids_${value}`}>
                        {key}
                      </StyledLabel>
                    </li>
                  ))}
                </StyledList>
                {validateErrors.form_user_frontend_contactable_time_range_update?.time_range_ids
                  ?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage
                      message={
                        validateErrors.form_user_frontend_contactable_time_range_update
                          ?.time_range_ids.message
                      }
                    />
                  </StyledErrorMessageOuter>
                )}
                <StyledOtherTimeRangeHeading>その他の時間を選択する</StyledOtherTimeRangeHeading>
                <StyledOtherTimeRangeSelectOuter>
                  <div>
                    <Pulldown
                      name="form_user_frontend_contactable_time_range_update[time_ranges_start]"
                      list={timeRangesStartList}
                      placeholder="開始時間"
                      isDisabled={isPulldownDisabled}
                      isError={
                        !!validateErrors.form_user_frontend_contactable_time_range_update
                          ?.time_ranges_start?.message
                      }
                    />
                  </div>
                  <div>〜</div>
                  <div>
                    <Pulldown
                      name="form_user_frontend_contactable_time_range_update[time_ranges_end]"
                      list={timeRangesEndList}
                      placeholder="終了時間"
                      isDisabled={isPulldownDisabled}
                      isError={
                        !!validateErrors.form_user_frontend_contactable_time_range_update
                          ?.time_ranges_end?.message
                      }
                    />
                  </div>
                </StyledOtherTimeRangeSelectOuter>
                {validateErrors.form_user_frontend_contactable_time_range_update?.time_ranges_start
                  ?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage
                      message={
                        validateErrors.form_user_frontend_contactable_time_range_update
                          .time_ranges_start.message
                      }
                    />
                  </StyledErrorMessageOuter>
                )}
                {validateErrors.form_user_frontend_contactable_time_range_update?.time_ranges_end
                  ?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage
                      message={
                        validateErrors.form_user_frontend_contactable_time_range_update
                          .time_ranges_end.message
                      }
                    />
                  </StyledErrorMessageOuter>
                )}
                {validateErrors.form_user_frontend_contactable_time_range_update
                  ?.userContactableTimeRanges?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage
                      message={
                        validateErrors.form_user_frontend_contactable_time_range_update
                          .userContactableTimeRanges.message
                      }
                    />
                  </StyledErrorMessageOuter>
                )}
              </dd>
              <dt>備考</dt>
              <dd>
                <TextArea
                  name="form_user_frontend_contactable_time_range_update[contactable_time_ranges_note]"
                  placeholder=""
                />
                {validateErrors.form_user_frontend_contactable_time_range_update
                  ?.contactable_time_ranges_note?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage
                      message={
                        validateErrors.form_user_frontend_contactable_time_range_update
                          .contactable_time_ranges_note.message
                      }
                    />
                  </StyledErrorMessageOuter>
                )}
              </dd>
            </DefinitionList>
          </BoxWithShadow>
          <SubmitAndCancelButtons handleOnClick={openModal} appOsType={appOsType} />
        </form>
      </FormProvider>
      <Suspense fallback={null}>
        {isModalOpen && (
          <CancelModal isModalOpen={isModalOpen} closeModal={closeModal} returnPath={returnPath} />
        )}
      </Suspense>
    </>
  );
};
