import { FC } from "react";
import { Icon } from "@root/shared/components/Icon";
import { Label } from "@root/shared/components/Label";
import { MultiLineText } from "@root/shared/components/MultiLineText";
import { Cassette } from "@root/shared/components/Cassette";
import { Link } from "@root/shared/components/Link";
import * as styles from "./styles.css";

type Props = {
  cassetteInformation: {
    continuedServiceYears?: string;
    employmentStatus: string;
    ageText?: string;
    employment?: string;
    businessType?: string;
    sex?: string;
    isWorkConfirmed: boolean;
    text: string;
    createdDate: string;
    textId: string;
    isTextHidden: boolean;
  };
  reviewSubmissionUrlOrRegistrationUrl?: string;
  isSp: boolean;
};

export const ClientReviewCassette: FC<Props> = ({
  cassetteInformation: {
    continuedServiceYears,
    employmentStatus,
    ageText,
    employment,
    businessType,
    sex,
    isWorkConfirmed,
    text,
    createdDate,
    textId,
    isTextHidden,
  },
  reviewSubmissionUrlOrRegistrationUrl,
  isSp,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.profile}>
        <div className={styles.iconWrapper}>
          <Icon name="human" fontSize="20px" />
        </div>
        <div className={styles.profileDetail}>
          <p className={styles.profileYears}>
            {continuedServiceYears && (
              <span className={styles.profileYearsBold}>{continuedServiceYears}</span>
            )}
            {employmentStatus}（回答時）
          </p>
          <div className={styles.profileLabelWrapper}>
            {ageText && <Label theme="lightPink">{ageText}</Label>}
            {employment && <Label theme="lightPink">{employment}</Label>}
            {businessType && <Label theme="lightPink">{businessType}</Label>}
            {sex && <Label theme="lightPink">{sex}</Label>}
            {isWorkConfirmed && (
              <Label icon={<Icon name="done" />} theme="deepPink">
                勤務確認済み
              </Label>
            )}
          </div>
        </div>
      </div>
      <div className={styles.matchComment}>
        <MultiLineText text={text} />
        {isTextHidden && (
          <div className={styles.hiddenTextWrapper}>
            <Cassette className={styles.cassette} width={isSp ? "" : "296px"}>
              <p className={styles.cassetteTopText}>投稿すると続きが見れます</p>
              <div className={styles.linkWrapper}>
                <Link theme="primary" href={reviewSubmissionUrlOrRegistrationUrl}>
                  匿名で投稿する
                </Link>
              </div>
              <p className={styles.cassetteText}>
                投稿には会員登録/ログインが必要です。
                <br />
                求人掲載中の企業に対してのみ投稿が可能です。
              </p>
            </Cassette>
          </div>
        )}
      </div>
      <p className={styles.matchDate}>
        {createdDate}回答 ID {textId}
      </p>
    </div>
  );
};
