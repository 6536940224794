import { FC } from "react";

export interface WorkTimesProps {
  workTimes: {
    employmentTypeName: string;
    workTimesByEmploymentType: string;
  }[];
}

export const WorkTimes: FC<WorkTimesProps> = ({ workTimes }) => (
  <>
    {workTimes.map(({ employmentTypeName, workTimesByEmploymentType }) => (
      <p key={`${employmentTypeName}_${workTimesByEmploymentType}`}>
        {employmentTypeName} /&nbsp;
        <b>{workTimesByEmploymentType}</b>
      </p>
    ))}
  </>
);
