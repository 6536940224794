import { FC } from "react";
import { Bubble } from "react-chartjs-2";
import { Colors } from "@root/utils/colors";
import * as styles from "./styles.css";

type Props = {
  leftItemLabel: string;
  rightItemLabel: string;
  x: number;
  markerValues: [number, number, ...number[]];
};

export const RatioWithDotChart: FC<Props> = ({
  leftItemLabel,
  rightItemLabel,
  x,
  markerValues,
}) => {
  const xMin = Math.min(...markerValues);
  const xMax = Math.max(...markerValues);
  const adjustedX = Math.min(Math.max(x, xMin), xMax);

  const middleValue = (xMin + xMax) / 2;
  const leftItemClass = x < middleValue ? `${styles.leftItem} is-selected` : styles.leftItem;
  const rightItemClass = x > middleValue ? `${styles.rightItem} is-selected` : styles.rightItem;

  const data = {
    datasets: [
      {
        data: [
          { x: adjustedX, y: 5, r: 10 },
          { x: adjustedX, y: 5, r: 6 },
        ],
        backgroundColor: [Colors.REJOB_BRAND_DEEP, Colors.WHITE],
        borderColor: "null",
        fill: "true",
        type: "bubble",
      },
      {
        data: markerValues.map((value) => ({ x: value, y: 5 })),
        type: "line",
        fill: "false",
        pointRadius: 5,
        pointStyle: "circle",
        pointBorderColor: "transparent",
        borderWidth: 1,
        backgroundColor: Colors.GRAY_SCALE_D9,
      },
    ],
  };

  const options = {
    elements: {
      line: {
        tension: 0.000001,
      },
    },
    animation: false,
    tooltips: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    hover: {
      mode: null,
    },
    layout: {
      padding: {
        right: 10,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            stepSize: 0.5,
            xMin,
            xMax,
            display: false,
          },
          scaleLabel: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            stepSize: 5,
            min: 0,
            max: 10,
            display: false,
          },
          scaleLabel: {
            display: false,
          },
        },
      ],
    },
  };

  return (
    <div className={`${styles.itemWrapper}`}>
      <div className={`${styles.item} ${leftItemClass}`}>{leftItemLabel}</div>
      <div className={`${styles.chartWrapper}`}>
        <Bubble data={data} options={options} height={45} />
      </div>
      <div className={`${styles.item} ${rightItemClass}`}>{rightItemLabel}</div>
    </div>
  );
};
