import { ComponentProps, FC, useState, useEffect, Fragment } from "react";
import { PremiumBanner } from "./src/premium_banner";
import { OiwaiBanner } from "./src/oiwai_banner";
import { Cassette } from "./src/cassette";
import { CassetteProps } from "./src/cassette/src/cassette_props";

interface Props {
  fetchUrl: string;
  dummyImgPath: string;
  login: boolean;
  isSp: boolean;
  keepedFlags: boolean[];
  oiwaiBanner?: OiwaiBannerProps;
  premiumBanner?: PremiumBannerProps;
  cassetteType?: string;
  showAwardsLabelFlag?: boolean;
  isContractRunning: boolean;
}

type OiwaiBannerProps = ComponentProps<typeof OiwaiBanner>;
type PremiumBannerProps = ComponentProps<typeof PremiumBanner>;

export const Cassettes: FC<Props> = ({
  fetchUrl,
  dummyImgPath,
  login,
  isSp,
  keepedFlags,
  oiwaiBanner,
  premiumBanner,
  cassetteType,
  showAwardsLabelFlag,
  isContractRunning,
}) => {
  const [cassettes, setCassettes] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await fetch(fetchUrl);
        const json = await res.json();
        setCassettes(json.data);
      } catch {
        // 何もしない
      }
    })();
  }, [fetchUrl]);

  return (
    <>
      {cassettes.map((cassette: CassetteProps["cassetteHash"], index) => (
        <Fragment key={cassette.workCode}>
          {(() => {
            if (oiwaiBanner && (index === 6 || index === 10 || index === 15)) {
              let { gaAction } = oiwaiBanner;
              switch (index) {
                case 6:
                  gaAction = `${gaAction}-top`;
                  break;
                case 10:
                  gaAction = `${gaAction}-middle1`;
                  break;
                case 15:
                  gaAction = `${gaAction}-bottom`;
                  break;
                default:
              }
              return (
                <OiwaiBanner
                  url={oiwaiBanner.url}
                  modalPath={oiwaiBanner.modalPath}
                  gaAction={gaAction}
                  gaLabel={oiwaiBanner.gaLabel}
                  lazyLoadImage={oiwaiBanner.lazyLoadImage}
                />
              );
            }
            return null;
          })()}
          <Cassette
            cassetteHash={cassette}
            dummyImgPath={dummyImgPath}
            login={login}
            isSp={isSp}
            keepedFlag={keepedFlags[index]}
            cassetteType={cassetteType}
            showAwardsLabelFlag={showAwardsLabelFlag}
            isContractRunning={isContractRunning}
          />
          {premiumBanner && index === 4 && (
            <PremiumBanner
              url={premiumBanner.url}
              gaAction={premiumBanner.gaAction}
              gaLabel={premiumBanner.gaLabel}
              query={premiumBanner.query}
              lazyLoadImage={premiumBanner.lazyLoadImage}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};
