export const Colors: { [key: string]: string } = {
  BRAND_PINK_DEEP: "#ee828f",
  LIGHT_RED: "#ff7878",
  MEDIUM_PINK: "#ff4c4c",
  LIGHT_PINK: "#ff8d8d",
  BLACK: "#333",
  OLD_DEEP_BLACK: "#000",
  GREY: "#eee",
  GREY_01: "#a8a8a8",
  OLD_MEDIUM_GREY: "#4f4f4f",
  OLD_LIGHT_GREY: "#f9f9fb",
  LIGHT_GREY_01: "#f5f5f5",
  LIGHT_GRAY_02: "#fafafa",
  ORANGE: "#f97b0b",
  MEDIUM_ORANGE: "#eb714c",
  MEDIUM_YELLOW: "#ebdc4d",
  LINK_BLUE_OLD: "#2D83BE",
  LINK_BLUE_OLD_02: "#227CBA",
  ALERT_RED: "#ef4242",
  APP_PROMOTION_BANNER_PINK: "#fb8cb1",
  AWARDS_LABEL_GOLD: "#cca62b",
  AWARDS_LABEL_SILVER: "#a0b0c7",
  AWARDS_LABEL_BRONZE: "#e28d4e",
  AWARDS_LABEL_SELECTED: "#f67e9b",
  FOCUS_BLUE: "#0f71d2",
  LOADING_BLUE: "#8fd5ed",

  REJOB_BRAND_DEEP: "#F96075",
  REJOB_BRAND_LIGHT: "#FFF5F7",

  PRIMARY: "#FB6341",

  LINK: "#0F71D2",
  LIGHT_BLUE: "#99DDFF",

  ALERT: "#EF4242",

  DEEP_BLACK: "#222",
  LIGHT_BLACK: "#575757",
  DEEP_GREY: "#949494",
  MEDIUM_GREY: "#D9D9D9",
  LIGHT_GREY: "#F2F2F2",
  BLUE_GREY: "#EEEEF3",
  WHITE: "#FFF",
};
