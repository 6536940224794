/* eslint-disable camelcase */
import { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import {
  SelectedCredentialLabelsProps,
  WatchFieldProps,
} from "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/credentials_edit_props";
import { StyledLabelList } from "@legacy_user_frontend/components/pages/mypage/profiles/credentials_edit/src/styled_elements";
import { Label } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/label";
import { LabelsCassette } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/labels_cassette";

export const SelectedCredentialLabels: FC<SelectedCredentialLabelsProps> = ({
  categorizedCredentialItemList,
  otherCredentialItemList,
}) => {
  const { watch } = useFormContext();

  // 選択済みの資格
  const watchField: WatchFieldProps[] = watch(
    "form_user_frontend_user_update[credentials_attributes]",
  ).filter((credential: WatchFieldProps) => credential.user_credential_item_id);
  const credentials = useMemo(
    () => watchField.filter((credential) => credential.user_credential_item_id),
    [watchField],
  );

  const idToCredentialName = (id: number) => {
    let credentialName = "";
    categorizedCredentialItemList.forEach((categoryItem) => {
      categoryItem.jobTypes.forEach((jobTypeItem) => {
        jobTypeItem.credentialItems.forEach(({ key, value }) => {
          if (value === id) {
            credentialName = key;
          }
        });
      });
    });
    if (credentialName) return credentialName;
    otherCredentialItemList.forEach(({ key, value }) => {
      if (value === id) {
        credentialName = key;
      }
    });
    return credentialName;
  };

  if (credentials.length === 0) {
    return null;
  }

  const credentialNames: string[] = [];
  return (
    <LabelsCassette heading="追加済みの資格">
      <StyledLabelList>
        {credentials.map((credential) => {
          const credentialName = idToCredentialName(credential.user_credential_item_id);
          if (credentialNames.find((item) => item === credentialName) === undefined) {
            credentialNames.push(credentialName);
            return (
              <li key={credentialName}>
                <Label>
                  <>
                    {credentialName}
                    {credential.will_flag && <>(取得予定)</>}
                    {!credential.will_flag && <>(取得済み)</>}
                  </>
                </Label>
              </li>
            );
          }
          return undefined;
        })}
      </StyledLabelList>
    </LabelsCassette>
  );
};
