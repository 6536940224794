/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, lazy, Suspense, useCallback, useEffect, useRef, useState } from "react";
import { FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { SubmitAndCancelButtons } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/submit_and_cancel_buttons";
import { AdditionButton } from "@userFrontend/components/Buttons/AdditionButton";
import { ToSnakeCase } from "@legacy_root/utils";
import { showAlertBeforeBrowserBack } from "@legacy_root/user_frontend/components/pages/mypage/profiles/utils/show_alert_before_browser_back";
import { CareerCassette } from "./src/ui_parts/career_cassette";
import { UserCareerEditProps, InputProps } from "./src/user_career_edit_props";
import { StyledAdditionButtonOuter } from "./src/styled_elements";

const CancelModal = lazy(
  () => import("@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/cancel_modal"),
);

export const UserCareerEdit: FC<UserCareerEditProps> = ({
  action,
  returnPath,
  userCareers,
  employmentYearList,
  employmentMonthList,
  employmentPatternList,
  jobTypeCategoryList,
  workPatternList,
  yearsOfExperienceList,
  isBiyo,
  appOsType,
}) => {
  // デフォルト値の設定
  const userCareersDefaultValues: InputProps["user_careers_update"]["user_careers_attributes"] =
    userCareers.map((career) => ({
      // watchで差分を比較する際に、一部の項目のnumberがstringに変換されてしまうため、予めstringに変換する
      id: career.id,
      company_name: career.companyName,
      shop_name: career.shopName,
      user_employment_pattern_id: career.userEmploymentPatternId,
      employment_start_year: career.employmentStartYear,
      employment_start_month: career.employmentStartMonth,
      employment_end_year: career.employmentEndYear,
      employment_end_month: career.employmentEndMonth,
      occupation: career.occupation,
      job_type_category_id: `${career.jobTypeCategoryId}`,
      job_type_id: `${career.jobTypeId}`,
      clientele: career.clientele,
      average_number_of_customers: career.averageNumberOfCustomers,
      average_percentage_of_new_customers: career.averagePercentageOfNewCustomers,
      average_nomination_ratio: career.averageNominationRatio,
      average_customer_spending: career.averageCustomerSpending,
      average_personal_sales: career.averagePersonalSales,
      user_career_work_patterns: career.userCareerWorkPatterns.map(
        (workPattern) => `${workPattern}`,
      ),
      user_careers_facility_types_attributes: career.userCareersFacilityTypes.map(
        (facilityType) => ({
          facility_type_id: facilityType.facilityTypeId,
          years_of_experience: facilityType.yearsOfExperience,
        }),
      ),
      description: career.description,
    }));
  const methods = useForm<InputProps>({
    defaultValues: {
      authenticity_token: "",
      _method: "put",
      user_careers_update: {
        user_careers_attributes: userCareersDefaultValues,
      },
    },
  });

  // 業種、職種の選択状況をstateで一括管理する
  const [selectedJobTypeIds, setSelectedJobTypeIds] = useState<(number | string)[]>(
    userCareers.map(({ jobTypeId }) => jobTypeId),
  );
  const [selectedJobTypeCategoryIds, setSelectedJobTypeCategoryIds] = useState<(number | string)[]>(
    userCareers.map(({ jobTypeCategoryId }) => jobTypeCategoryId),
  );

  // サーバー側から渡ってきたエラーメッセージを項目に反映する
  const errorName = "user_careers_update.user_careers_attributes";
  const { setError } = methods;
  useEffect(() => {
    const defaultErrors = userCareers.map((career) => career.errors);
    const setErrorMessage = (key: string, index: number) => {
      const errorFormName = ToSnakeCase(`${errorName}.${index}.${key}`) as keyof InputProps;
      setError(
        errorFormName,
        {
          type: "manual",
          message: defaultErrors[index][key],
        },
        { shouldFocus: index === 0 },
      );
    };
    defaultErrors.forEach((error, index) => {
      Object.keys(error).map((key) => setErrorMessage(key, index));
    });
    userCareers.forEach((career, careerIndex) => {
      career.userCareersFacilityTypes.forEach((facilityType, index) => {
        const facilityTypeObject = Object.keys(facilityType.errors);
        if (facilityTypeObject.length) {
          facilityTypeObject.forEach((error) => {
            const errorFormName =
              `${errorName}.${careerIndex}.user_careers_facility_types_attributes.${index}.${ToSnakeCase(
                error,
              )}` as keyof InputProps;
            setError(
              errorFormName,
              {
                type: "manual",
                message: facilityType.errors[error],
              },
              { shouldFocus: careerIndex === 0 },
            );
          });
        }
      });
    });
  }, [userCareers, setError]);

  // 職務経歴のfieldArray
  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "user_careers_update.user_careers_attributes",
  });
  const appendField = useCallback(
    (fieldId: number) => {
      append(
        {
          company_name: "",
          shop_name: "",
          user_employment_pattern_id: "",
          employment_start_year: "",
          employment_start_month: "",
          employment_end_year: "",
          employment_end_month: "",
          occupation: "",
          job_type_category_id: "",
          job_type_id: "",
          clientele: "",
          average_number_of_customers: "",
          average_percentage_of_new_customers: "",
          average_nomination_ratio: "",
          average_customer_spending: "",
          average_personal_sales: "",
          user_career_work_patterns: [],
          user_careers_facility_types_attributes: [],
          description: "",
        },
        { shouldFocus: true },
      );
      setSelectedJobTypeIds(selectedJobTypeIds.map((id, index) => (index === fieldId ? "" : id)));
      setSelectedJobTypeCategoryIds(
        selectedJobTypeCategoryIds.map((id, index) => (index === fieldId ? "" : id)),
      );
    },
    [append, selectedJobTypeCategoryIds, selectedJobTypeIds],
  );
  const [isRemoved, setIsRemoved] = useState(false);
  const removeField = (fieldId: number) => {
    remove(fieldId);
    setIsRemoved(true);
    setSelectedJobTypeIds(selectedJobTypeIds.filter((_, index) => index !== fieldId));
    setSelectedJobTypeCategoryIds(
      selectedJobTypeCategoryIds.filter((_, index) => index !== fieldId),
    );
  };
  useEffect(() => {
    if (fields.length === 0) {
      appendField(0);
    }
  }, [appendField, fields.length]);

  // URLにアンカーリンクがあった場合の対応
  useEffect(() => {
    const anchor = window.location.hash;
    if (!anchor) {
      return;
    }
    // 一覧画面で追加ボタンを押して遷移した場合はカセットを1つ追加し、新しいカセットにスクロールする
    if (anchor === `#cassette-${fields.length}`) {
      appendField(fields.length);
      return;
    }
    // それ以外の場合は対応するカセットにスクロールする
    window.location.href = anchor;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 変更の検出
  const watchedValues = methods.watch("user_careers_update.user_careers_attributes");
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    userCareersDefaultValues.forEach((defaultValue) => {
      // watchedValuesにはuser_careers_facility_types_attributesが空の場合もオブジェクトが入るため、比較用に追加する
      if (defaultValue.user_careers_facility_types_attributes.length === 0) {
        defaultValue.user_careers_facility_types_attributes.push({
          facility_type_id: "",
          years_of_experience: "",
        });
      }
    });
    if (JSON.stringify(watchedValues) !== JSON.stringify(userCareersDefaultValues)) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [userCareersDefaultValues, watchedValues]);

  // キャンセルボタン押下時に確認モーダルを表示する
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    if (isChanged) {
      setIsModalOpen(true);
      setIsShowAlert(false);
      return;
    }
    window.location.href = returnPath;
  };
  const closeModal = () => {
    setIsShowAlert(true);
    setIsModalOpen(false);
  };

  // ブラウザバックを検知して確認モーダルを表示する
  const ua = window.navigator.userAgent.toLowerCase();
  useEffect(() => {
    // 変更がない場合のほか、safariとiOSの場合（画面遷移後の動作に影響があるためブラウザバックを許容）は処理を抜ける
    if (
      ua.indexOf("iphone") > 0 ||
      ua.indexOf("ipod") > 0 ||
      (ua.indexOf("chrome") === -1 && ua.indexOf("safari") !== -1) ||
      !isChanged
    ) {
      return;
    }
    showAlertBeforeBrowserBack({ setIsShowAlert, setIsModalOpen });
  }, [isChanged, ua]);

  // 画面遷移を検知して確認アラートを表示する
  useEffect(() => {
    const showAlert = (event: BeforeUnloadEvent) => {
      if (!isChanged || !isShowAlert) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", showAlert);
    return () => window.removeEventListener("beforeunload", showAlert);
  }, [isChanged, isShowAlert]);

  // 送信処理
  const formEl = useRef<HTMLFormElement>(null);
  const [isValidatePassed, setIsValidatePassed] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const onSubmit: SubmitHandler<InputProps> = (data) => {
    setIsSubmitted(false);
    setIsValidatePassed(true);
    // バリデーションチェック
    data.user_careers_update.user_careers_attributes.forEach(
      (
        {
          company_name,
          shop_name,
          user_employment_pattern_id,
          occupation,
          user_careers_facility_types_attributes,
          employment_start_year,
          employment_start_month,
          employment_end_year,
          employment_end_month,
          job_type_category_id,
          job_type_id,
        },
        attributeIndex,
      ) => {
        // 何かしら入力されていて業種が選択されていない場合のエラー
        if (
          !job_type_category_id &&
          (company_name ||
            shop_name ||
            user_employment_pattern_id ||
            employment_start_year ||
            employment_start_month ||
            employment_end_year ||
            employment_end_month ||
            occupation)
        ) {
          methods.setError(
            `user_careers_update.user_careers_attributes.${attributeIndex}.job_type_category_id`,
            {
              type: "required",
              message: "業種を選択してください。",
            },
            {
              shouldFocus: true,
            },
          );
          setIsValidatePassed(false);
        }
        // 業種選択済み+職種未選択の場合のエラー
        if (job_type_category_id && !job_type_id) {
          methods.setError(
            `user_careers_update.user_careers_attributes.${attributeIndex}.job_type_id`,
            {
              type: "required",
              message: "職種を選択してください。",
            },
            {
              shouldFocus: true,
            },
          );
          setIsValidatePassed(false);
        }
        // 経験年数と施設形態のバリデーションエラー
        if (employment_start_year && !employment_start_month) {
          methods.setError(
            `user_careers_update.user_careers_attributes.${attributeIndex}.employment_start_month`,
            {
              type: "required",
              message: "在籍期間開始月を入力してください。",
            },
            {
              shouldFocus: true,
            },
          );
          setIsValidatePassed(false);
        }
        if (!employment_start_year && employment_start_month) {
          methods.setError(
            `user_careers_update.user_careers_attributes.${attributeIndex}.employment_start_year`,
            {
              type: "required",
              message: "在籍期間開始年を入力してください。",
            },
            {
              shouldFocus: true,
            },
          );
          setIsValidatePassed(false);
        }
        if (employment_end_year && !employment_end_month) {
          methods.setError(
            `user_careers_update.user_careers_attributes.${attributeIndex}.employment_end_month`,
            {
              type: "required",
              message: "在籍期間終了月を入力してください。",
            },
            {
              shouldFocus: true,
            },
          );
          setIsValidatePassed(false);
        }
        if (!employment_end_year && employment_end_month) {
          methods.setError(
            `user_careers_update.user_careers_attributes.${attributeIndex}.employment_end_year`,
            {
              type: "required",
              message: "在籍期間終了年を入力してください。",
            },
            {
              shouldFocus: true,
            },
          );
          setIsValidatePassed(false);
        }
        const facilityTypeIds = user_careers_facility_types_attributes.map(
          ({ facility_type_id }) => facility_type_id,
        );
        user_careers_facility_types_attributes.forEach(
          ({ years_of_experience, facility_type_id }, facilityTypesIndex) => {
            if (years_of_experience && !facility_type_id) {
              methods.setError(
                `user_careers_update.user_careers_attributes.${attributeIndex}.user_careers_facility_types_attributes.${facilityTypesIndex}.facility_type_id`,
                {
                  type: "required",
                  message: "施設形態を選択してください。",
                },
                {
                  shouldFocus: true,
                },
              );
              setIsValidatePassed(false);
            }
            if (!years_of_experience && facility_type_id) {
              methods.setError(
                `user_careers_update.user_careers_attributes.${attributeIndex}.user_careers_facility_types_attributes.${facilityTypesIndex}.years_of_experience`,
                {
                  type: "required",
                  message: "経験年数を選択してください。",
                },
                {
                  shouldFocus: true,
                },
              );
              setIsValidatePassed(false);
            }
            // 施設形態に同じIDが複数存在する場合（未選択を除く）は重複エラー
            if (
              facilityTypeIds.filter(
                (facilityTypeId) =>
                  Number(facilityTypeId) === Number(facility_type_id) && facilityTypeId,
              ).length > 1
            ) {
              methods.setError(
                `user_careers_update.user_careers_attributes.${attributeIndex}.user_careers_facility_types_attributes.${facilityTypesIndex}.facility_type_id`,
                {
                  type: "required",
                  message: "施設形態が重複しています。",
                },
                {
                  shouldFocus: true,
                },
              );
              setIsValidatePassed(false);
            }
          },
        );
      },
    );
    setIsSubmitted(true);
  };

  useEffect(() => {
    if (!isSubmitted) {
      return;
    }
    if (!isValidatePassed) {
      return;
    }
    setIsShowAlert(false);
    formEl.current?.submit();
  }, [isSubmitted, isValidatePassed]);

  return (
    <>
      <FormProvider {...methods}>
        <form ref={formEl} action={action} onSubmit={methods.handleSubmit(onSubmit)} method="post">
          <input type="hidden" {...methods.register("authenticity_token")} />
          <input type="hidden" {...methods.register("_method")} />
          {fields.map((field, index) => (
            <CareerCassette
              cassetteIndex={index}
              key={field.id}
              isBiyo={isBiyo}
              careerIndex={index}
              jobTypeCategoryId={selectedJobTypeCategoryIds[index]}
              jobTypeId={selectedJobTypeIds[index]}
              workPatterns={userCareers[index]?.userCareerWorkPatterns}
              removeField={removeField}
              employmentYearList={employmentYearList}
              employmentMonthList={employmentMonthList}
              employmentPatternList={employmentPatternList}
              jobTypeCategoryList={jobTypeCategoryList}
              workPatternList={workPatternList}
              yearsOfExperienceList={yearsOfExperienceList}
              isRemoved={isRemoved}
            />
          ))}
          <StyledAdditionButtonOuter>
            <AdditionButton
              onClick={() => {
                appendField(fields.length);
              }}
            >
              追加する
            </AdditionButton>
          </StyledAdditionButtonOuter>
          <SubmitAndCancelButtons handleOnClick={openModal} appOsType={appOsType} />
        </form>
      </FormProvider>
      <Suspense fallback={null}>
        {isModalOpen && (
          <CancelModal isModalOpen={isModalOpen} closeModal={closeModal} returnPath={returnPath} />
        )}
      </Suspense>
    </>
  );
};
