import { FC } from "react";
import styled from "styled-components";
import { Colors, Breakpoints } from "@legacy_user_frontend/utils";
import { Icon } from "@root/shared/components/Icon";
import { Label as NewLabel } from "@root/shared/components/Label/legacy";

type AwardsLabel = {
  rank: string;
  displayTextShort: string;
};

export interface FeatureLabelProps {
  rewardPrice: string;
  featureLabels: {
    selected?: string[];
    unselected?: string[];
  };
  awards2024Labels: AwardsLabel[];
  awards2023Labels: AwardsLabel[];
  awards2022Labels: AwardsLabel[];
  awardsLabels: AwardsLabel[];
  showAwardsLabelFlag?: boolean;
  isContractRunning: boolean;
  isReviewLabel: boolean;
  isRejobAgentClient: boolean;
}

const LabelList = styled.ul`
  flex-wrap: wrap;
  align-items: flex-end;
  list-style-type: none;
  display: flex;
  margin-top: 12px;
`;

const Label = styled.li`
  margin: 2px;
`;

const RevieLabel = styled.li`
  margin: auto 2px;
`;

const ReviewLabelInner = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: 73px;
`;

const LabelFeature = styled(Label)`
  ${Label};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  border: 1px solid;
  white-space: nowrap;
  font-size: 11px;
  line-height: 2;
  padding: 0 6px;
  color: ${Colors.LIGHT_PINK};
  border: 1px solid ${Colors.LIGHT_PINK};
  background-color: ${Colors.WHITE};
`;

const LabelAwardsOuter = styled(Label)`
  flex-basis: 100%;
  ${Breakpoints.MEDIUM} {
    flex-basis: auto;
  }
`;

const LabelAwards = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding-left: 21px;
  padding-right: 6px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-size: 13px auto;
  background-position: 6px center;
  color: ${Colors.WHITE};
  font-size: 11px;
  font-weight: bold;
  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
    right: -4px;
    width: 0;
    height: 0;
    border-style: solid;
  }
  &::before {
    top: 0;
    border-width: 12px 4px 0 0;
  }
  &::after {
    bottom: 0;
    border-width: 12px 0 0 4px;
  }

  &.cassette-award-label--gold {
    background-color: ${Colors.AWARDS_LABEL_GOLD};
    background-image: url("/images/user_frontend/global/icon/icn_awards_gold.svg");
    &::before {
      border-color: ${Colors.AWARDS_LABEL_GOLD} transparent transparent transparent;
    }
    &::after {
      border-color: transparent transparent transparent ${Colors.AWARDS_LABEL_GOLD};
    }
  }
  &.cassette-award-label--silver {
    background-color: ${Colors.AWARDS_LABEL_SILVER};
    background-image: url("/images/user_frontend/global/icon/icn_awards_silver.svg");
    &::before {
      border-color: ${Colors.AWARDS_LABEL_SILVER} transparent transparent transparent;
    }
    &::after {
      border-color: transparent transparent transparent ${Colors.AWARDS_LABEL_SILVER};
    }
  }
  &.cassette-award-label--bronze {
    background-color: ${Colors.AWARDS_LABEL_BRONZE};
    background-image: url("/images/user_frontend/global/icon/icn_awards_bronze.svg");
    &::before {
      border-color: ${Colors.AWARDS_LABEL_BRONZE} transparent transparent transparent;
    }
    &::after {
      border-color: transparent transparent transparent ${Colors.AWARDS_LABEL_BRONZE};
    }
  }
  &.cassette-award-label--selected {
    background-color: ${Colors.AWARDS_LABEL_SELECTED};
    background-image: url("/images/user_frontend/global/icon/icn_awards_selected.svg");
    &::before {
      border-color: ${Colors.AWARDS_LABEL_SELECTED} transparent transparent transparent;
    }
    &::after {
      border-color: transparent transparent transparent ${Colors.AWARDS_LABEL_SELECTED};
    }
  }
`;

const SearchedLabelFeature = styled(LabelFeature)`
  ${LabelFeature};
  color: ${Colors.WHITE};
  background: ${Colors.MEDIUM_PINK};
  border-color: ${Colors.MEDIUM_PINK};
`;

const Remaining = styled.span`
  color: ${Colors.LIGHT_PINK};
  margin-left: 4px;
`;

export const FeatureLabels: FC<FeatureLabelProps> = ({
  rewardPrice,
  featureLabels: { selected, unselected },
  awards2024Labels,
  awards2023Labels,
  awards2022Labels,
  awardsLabels,
  showAwardsLabelFlag,
  isContractRunning,
  isReviewLabel,
  isRejobAgentClient,
}) => {
  // Rejob Awardsのラベルは最新のものを優先して表示する
  const awardsLabel = getFirstValidAwardLabel(
    awards2024Labels,
    awards2023Labels,
    awards2022Labels,
    awardsLabels,
  );
  return (
    <LabelList>
      {/* awardsラベル 最も優先度の高い一つのみ表示 */}
      {showAwardsLabelFlag && awardsLabel && (
        <LabelAwardsOuter
          className="cassette-header__label-item cassette-header__label-item--awards"
          key={awardsLabel.displayTextShort}
        >
          <LabelAwards className={`cassette-award-label--${awardsLabel.rank}`}>
            {awardsLabel.displayTextShort}
          </LabelAwards>
        </LabelAwardsOuter>
      )}
      {/* 勤続支援金ラベル 人材紹介リジョブエージェントの求人には表示しない */}
      {isContractRunning && !isRejobAgentClient && (
        <Label>
          <span className={`c-label-oiwai c-label-oiwai--${rewardPrice}`} />
        </Label>
      )}
      {/* 口コミありラベル */}
      {isReviewLabel && (
        <RevieLabel>
          <NewLabel color="lightOrange" size="extraSmall" filledType="outlined" round="slight" bold>
            <ReviewLabelInner>
              <Icon name="message" fontSize="12px" />
              口コミあり
            </ReviewLabelInner>
          </NewLabel>
        </RevieLabel>
      )}
      {/* クエリに含まれるラベル */}
      {selected &&
        selected.map((item) => <SearchedLabelFeature key={item}>{item}</SearchedLabelFeature>)}
      {/* クエリに含まれないラベル */}
      {unselected && unselected.map((item) => <LabelFeature key={item}>{item}</LabelFeature>)}
      {!isContractRunning && <LabelFeature>募集終了</LabelFeature>}
      <Remaining>...</Remaining>
    </LabelList>
  );
};

const getFirstValidAwardLabel = (
  awards2024Labels: FeatureLabelProps["awards2024Labels"],
  awards2023Labels: FeatureLabelProps["awards2023Labels"],
  awards2022Labels: FeatureLabelProps["awards2022Labels"],
  awardsLabels: FeatureLabelProps["awardsLabels"],
): AwardsLabel | undefined => {
  const allLabels = [
    ...awards2024Labels,
    ...awards2023Labels,
    ...awards2022Labels,
    ...awardsLabels,
  ];
  return allLabels.find((label) => Boolean(label.rank && label.displayTextShort));
};
