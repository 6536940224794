import styled from "styled-components";
import { Breakpoints, Colors } from "@legacy_user_frontend/utils";

export const StyledBgOuter = styled.div`
  background: ${Colors.WHITE};
  border-radius: 4px;
`;

export const StyledOuter = styled.div`
  &:not(:first-of-type) {
    margin-top: 40px;
  }
  ${Breakpoints.LARGE} {
    &:not(:first-of-type) {
      margin-top: 30px;
    }
  }
`;

export const StyledCityParentOuter = styled.dt`
  margin-top: 8px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
`;

export const StyledParentOuter = styled.dd`
  font-weight: bold;
  padding-bottom: 10px;
`;

export const StyledParentLabel = styled.label`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  display: flex;
  align-items: center;

  > span {
    white-space: nowrap;
  }

  ${Breakpoints.LARGE} {
    font-size: 14px;
    cursor: pointer;
    margin-right: 0;
    width: 100%;
    padding: 0;
  }
`;

export const StyledCityParentLabel = styled(StyledParentLabel)`
  padding: 16px;
`;

export const StyledParentName = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  display: flex;

  > span {
    white-space: nowrap;
  }

  ${Breakpoints.LARGE} {
    font-size: 14px;
    display: flex;
    margin-right: 0;
    width: 100%;
    padding: 0;
  }
`;

export const StyledParentText = styled.p`
  width: 100%;
  font-size: 18px;
  font-weight: bold;

  ${Breakpoints.LARGE} {
    font-size: 14px;
  }
`;

export const StyledCheckBox = styled.input`
  && {
    -webkit-appearance: none;
    appearance: none;
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    position: relative;
    border: 1px solid ${Colors.GREY_01};
    border-radius: 2px;
    vertical-align: middle;
    margin-top: 0;
    margin-right: 8px;
    box-shadow: none;
    font-size: 1.2rem;
    margin-top: 0px;
    background: ${Colors.WHITE};
    display: inline-block;

    ${Breakpoints.LARGE} {
      width: 20px;
      height: 20px;
      display: block;
    }

    &:checked {
      background: ${Colors.REJOB_BRAND_DEEP};
      border: none;

      &::before,
      &::after {
        display: block;
        position: absolute;
        content: "";
        background: ${Colors.WHITE};
      }

      &::before {
        left: 3px;
        top: 15px;
        width: 8px;
        height: 4px;
        -webkit-transform: rotate(45deg);
        -webkit-transform-origin: right center;

        ${Breakpoints.LARGE} {
          left: 4px;
          top: 12px;
          width: 5px;
          height: 3px;
        }
      }

      &::after {
        left: 10px;
        top: 17px;
        width: 17px;
        height: 4px;
        -webkit-transform: rotate(-53deg);
        -webkit-transform-origin: left center;

        ${Breakpoints.LARGE} {
          left: 8px;
          top: 13px;
          width: 11px;
          height: 3px;
        }
      }
    }
  }
`;

export const StyledRadioButton = styled.input`
  && {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    position: relative;
    border-radius: 50%;
    background-color: ${Colors.WHITE};
    vertical-align: middle;
    margin-right: 8px;
    border: 1px solid ${Colors.DEEP_GREY};

    ${Breakpoints.LARGE} {
      width: 20px;
      height: 20px;
    }

    &:checked {
      border: 1px solid ${Colors.DEEP_GREY};
      &::before {
        position: absolute;
        left: 3px;
        top: 3px;
        display: block;
        border-radius: 100%;
        content: "";
        width: 17px;
        height: 17px;
        background: ${Colors.REJOB_BRAND_DEEP};

        ${Breakpoints.LARGE} {
          left: 2px;
          top: 2px;
          width: 14px;
          height: 14px;
        }
      }
    }

    &:focus {
      box-shadow: none;
    }
  }
`;

export const StyledChildLists = styled.ul`
  border-top: 1px solid ${Colors.BLUE_GREY};
  flex-direction: column;

  ${Breakpoints.LARGE} {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex-basis: -webkit-calc(100% - 130px);
    flex-basis: calc(100% - 130px);
    border-left: 1px solid ${Colors.BLUE_GREY};
    border-top: none;

    &:nth-child(-n + 3) {
      border-top: 1px solid ${Colors.BLUE_GREY};
    }
  }
`;

export const StyledChildList = styled.li`
  align-items: center;
  font-size: 13px;

  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.BLUE_GREY};
  }

  ${Breakpoints.LARGE} {
    padding: 0;
    flex-basis: 33.33%;
    max-width: 33.33%;
    height: auto;
    border-right: 1px solid ${Colors.BLUE_GREY};
    &:last-child {
      border-bottom: 1px solid ${Colors.BLUE_GREY};
    }
  }
`;

export const StyledAccordionChildList = styled.li`
  font-size: 13px;
  padding-left: 16px;

  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.BLUE_GREY};
  }

  ${Breakpoints.LARGE} {
    flex-basis: 33.33%;
    max-width: 33.33%;
    height: auto;
    padding-left: 0;
    border-right: 1px solid ${Colors.BLUE_GREY};
    display: flex;
    align-items: center;

    &:last-child {
      border-bottom: 1px solid ${Colors.BLUE_GREY};
    }

    &:nth-child(-n + 3) {
      border-top: 1px solid ${Colors.BLUE_GREY};
    }
  }
`;

export const StyledChildLabel = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  line-height: 30px;
  font-size: 13px;
  padding: 11px 0;

  > span {
    white-space: nowrap;
  }

  ${Breakpoints.LARGE} {
    line-height: 16px;
    padding: 12px 0 12px 15px;
    border: none;
    position: relative;
    cursor: pointer;
  }
`;

interface AccordionOuterProps {
  isOpen: boolean;
}

export const StyledCityChildOuter = styled.dd<AccordionOuterProps>`
  padding: 0 16px;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};

  ${Breakpoints.LARGE} {
    display: block;
    padding: 0 16px 16px 16px;
  }
`;

export const StyledAccordionLabel = styled.label`
  padding: 16px 16px 16px 9px;
  text-align: right;
  display: block;
  width: 100%;

  > span {
    white-space: nowrap;
  }

  ${Breakpoints.LARGE} {
    display: none;
  }
`;

export const StyledAccordionButton = styled.input`
  && {
    -webkit-appearance: none;
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url("/images/shared/icon/icn_down_circled_gray.svg") no-repeat;
    background-size: contain;
    border: none;
    margin: 0;
    cursor: pointer;

    &:checked {
      border: none !important;
      transform: scale(1, -1);
      background: url("/images/shared/icon/icn_down_circled_gray.svg") no-repeat !important;
    }

    &::before,
    &::after {
      display: none !important;
    }

    ${Breakpoints.LARGE} {
      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const StyledCityAccordionButton = styled(StyledAccordionButton)`
  && {
    ${Breakpoints.LARGE} {
      display: none;
    }
  }
`;

export const StyledLink = styled.a<{ isTitleLink?: boolean }>`
  padding: 0 3px;
  font-size: 18px;
  line-height: 1;
  color: ${Colors.DEEP_GREY};
  &:hover {
    text-decoration: none;
    opacity: 0.7;
  }
  ${Breakpoints.LARGE} {
    margin-left: auto;
    margin-right: 0;
    padding: 6px 12px;
    ${({ isTitleLink }) => (isTitleLink ? "" : `border-left: 1px solid ${Colors.BLUE_GREY};`)}
  }
`;
