import { ComponentProps, FC } from "react";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import { MultiLineText } from "@root/shared/components/MultiLineText";
import { StyledDiv, StyledHeading } from "./src/styled_elements";

interface Props {
  heading: string;
  image?: ImageProps;
  body: string;
}

type ImageProps = ComponentProps<typeof LazyLoadImage>;

export const ClientOwnerContents: FC<Props> = ({ heading, image, body }) => (
  <section className="p-job-group-inner">
    <StyledHeading>{heading}</StyledHeading>
    <StyledDiv>
      {image && (
        <div className="owner-image">
          <LazyLoadImage
            src={image.src}
            dummyImgPath={image.dummyImgPath}
            alt={image.alt}
            width={image.width}
            height={image.height}
          />
        </div>
      )}
      <div className="owner-message js-toggle-text-area">
        <MultiLineText text={body} />
      </div>
    </StyledDiv>
  </section>
);
