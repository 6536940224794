/* eslint-disable react/jsx-props-no-spreading */
import { FC, ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { Colors } from "@legacy_user_frontend/utils";

type TextAreaProps = {
  name: string;
  placeholder: string;
  requiredMessage?: string;
  isError?: boolean;
  isDisabled?: boolean;
  height?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

type StyledTextAreaProps = {
  isError?: boolean;
  height?: number;
};

const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  appearance: none;

  &:not([type="submit"]) {
    width: 100%;
    height: ${({ height }) => (height ? `${height}px` : "92px")};
    padding: 8px;
    border: 1px solid ${({ isError }) => (isError ? Colors.ALERT_RED : Colors.MEDIUM_GREY)};
    background-color: ${Colors.WHITE};
    box-shadow: ${({ isError }) => (isError ? `0px 0px 8px ${Colors.ALERT_RED}` : "none")};
    font-size: 14px;
    line-height: 1.5;
    resize: vertical;
    outline: none;
    transition:
      border-color 0.1s,
      box-shadow 0.1s;

    &:focus {
      border-color: ${Colors.FOCUS_BLUE};
      box-shadow:
        0px 0px 8px ${Colors.FOCUS_BLUE},
        inset 0px 0px 1px ${Colors.FOCUS_BLUE};
    }

    &:disabled {
      background-color: ${Colors.LIGHT_GREY};
      color: ${Colors.LIGHT_BLACK};
    }

    ::placeholder {
      color: ${Colors.DEEP_GREY};
    }
  }
`;

export const TextArea: FC<TextAreaProps> = ({
  name,
  placeholder,
  requiredMessage,
  onChange,
  isError,
  isDisabled,
  height,
}) => {
  const { register, clearErrors } = useFormContext();
  const deleteErrorMessage = () => {
    clearErrors(name);
  };
  return (
    <StyledTextArea
      placeholder={placeholder}
      isError={isError}
      disabled={isDisabled}
      height={height}
      {...register(name, {
        required: requiredMessage,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          if (onChange) onChange(e);
          deleteErrorMessage();
        },
      })}
    />
  );
};
