import styled from "styled-components";

export const StyledModalContent = styled.div`
  text-align: center;
`;

export const StyledModalTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

export const StyledModalButtonOuter = styled.div`
  padding-top: 32px;
`;

export const StyledModalCloseLinkOuter = styled.div`
  padding-top: 24px;
  text-align: center;
`;

export const StyledSubmitButtonOuter = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 32px;
  padding: 0 16px;

  > button {
    flex-basis: 50%;
  }
`;
