import { FC, lazy } from "react";
import { Button } from "@root/shared/components/Button";
import styled from "styled-components";
import {
  StyledModalContent,
  StyledModalTitle,
  StyledModalButtonOuter,
  StyledModalCloseLinkOuter,
} from "./src/styled_elements";

const Modal = lazy(() => import("@root/shared/components/Modal/legacy"));

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
  returnPath: string;
};

const CancelModal: FC<Props> = ({ isModalOpen, closeModal, returnPath }) => (
  <Modal isShow={isModalOpen} onClose={closeModal} isBottomSheetMode={false}>
    <StyledModalContent>
      <StyledModalTitle>
        編集内容は
        <br />
        保存されていません
      </StyledModalTitle>
      <StyledModalButtonOuter>
        <ButtonWrapper>
          <Button theme="primary" onClick={closeModal}>
            編集を続ける
          </Button>
        </ButtonWrapper>
      </StyledModalButtonOuter>
      <StyledModalCloseLinkOuter>
        <a href={returnPath}>このページを離れる</a>
      </StyledModalCloseLinkOuter>
    </StyledModalContent>
  </Modal>
);

const ButtonWrapper = styled.div`
  height: 48px;
`;

export default CancelModal;
