/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { FC, lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { TextArea, ValidationErrorMessage } from "@legacy_user_frontend/components/ui_parts";
import { DefinitionList } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/definition_list";
import { SubmitAndCancelButtons } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/submit_and_cancel_buttons";
import { BoxWithShadow } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/box_with_shadow";
import styled from "styled-components";
import { ToSnakeCase } from "@legacy_root/utils";
import { showAlertBeforeBrowserBack } from "@legacy_root/user_frontend/components/pages/mypage/profiles/utils/show_alert_before_browser_back";
import { InputProps, SelfPrEditProps } from "./src/self_pr_edit_props";

const CancelModal = lazy(
  () => import("@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/cancel_modal"),
);

// textareaに渡すplaceholderの不要なスペースを削除
const removeSpace = (placeholder: string) => placeholder.replace(/ +/g, "");

const StyledDescription = styled.div`
  padding-bottom: 24px;
`;

const StyledErrorMessageOuter = styled.div`
  margin-top: 10px;
`;

export const SelfPrEdit: FC<SelfPrEditProps> = ({
  action,
  reasonForApply,
  importantPoint,
  futurePlan,
  selfPr,
  errors,
  returnPath,
  appOsType,
}) => {
  const textAreaHeight = 165;
  // placeholderに改行を含むので、変数化しておきremoveSpaceで不要な半角スペースを削除してからコンポーネントに渡す
  const placeholderReasonForApply = `高校時代に出会った方の影響が大きいです。
  その方は、いきいきと楽しそうに働いていて、誰かを笑顔にできる仕事は素敵だと思い、私自身もこの業界で働いてみたいという気持ちが強くなりました。`;
  const placeholderImportantPoint = `リラックスできるように、お声がけや雰囲気づくりなどを心がけていました。
  気持ちに寄り添い、要望の奥にある叶えたいことを引き出すことを心がけていました。`;
  const placeholderFuturePlan = `人を癒せる人たちの環境を整え、従業員もお客様も癒せるような環境にしていきたいと思っております。
  そのために、まずは現場で数をこなし、様々な経験を積みたいと思っております。`;
  // 初期値の設定
  const defaultValues = useMemo(
    () => ({
      reason_for_apply: reasonForApply,
      important_point: importantPoint,
      future_plan: futurePlan,
      self_pr: selfPr,
    }),
    [futurePlan, importantPoint, reasonForApply, selfPr],
  );

  const methods = useForm<InputProps>({
    defaultValues: {
      authenticity_token: "",
      _method: "put",
      form_user_frontend_self_pr_update: defaultValues,
    },
  });
  // サーバー側から渡ってきたエラーメッセージを項目に反映する
  const { setError, formState } = methods;
  const setErrorMessage = useCallback(
    (key: string, index: number) => {
      const errorFormName = `form_user_frontend_self_pr_update.${ToSnakeCase(
        key,
      )}` as keyof InputProps;
      setError(
        errorFormName,
        {
          type: "manual",
          message: errors[key],
        },
        { shouldFocus: index === 0 },
      );
    },
    [errors, setError],
  );
  useEffect(() => {
    Object.keys(errors).forEach((key, index) => {
      setErrorMessage(key, index);
    });
  }, [errors, setErrorMessage]);
  const validateErrors = formState.errors.form_user_frontend_self_pr_update;

  // 変更の検出
  const watchedValues = methods.watch("form_user_frontend_self_pr_update");
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    if (JSON.stringify(watchedValues) !== JSON.stringify(defaultValues)) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [defaultValues, watchedValues]);

  // キャンセルボタン押下時に確認モーダルを表示する
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    if (isChanged) {
      setIsModalOpen(true);
      setIsShowAlert(false);
      return;
    }
    window.location.href = returnPath;
  };
  const closeModal = () => {
    setIsShowAlert(true);
    setIsModalOpen(false);
  };

  // ブラウザバックを検知して確認モーダルを表示する
  const ua = window.navigator.userAgent.toLowerCase();
  useEffect(() => {
    // 変更がない場合のほか、safariとiOSの場合（画面遷移後の動作に影響があるためブラウザバックを許容）は処理を抜ける
    if (
      ua.indexOf("iphone") > 0 ||
      ua.indexOf("ipod") > 0 ||
      (ua.indexOf("chrome") === -1 && ua.indexOf("safari") !== -1) ||
      !isChanged
    ) {
      return;
    }
    showAlertBeforeBrowserBack({ setIsShowAlert, setIsModalOpen });
  }, [isChanged, ua]);

  // 画面遷移を検知して確認アラートを表示する
  useEffect(() => {
    const showAlert = (event: BeforeUnloadEvent) => {
      if (!isChanged || !isShowAlert) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", showAlert);
    return () => window.removeEventListener("beforeunload", showAlert);
  }, [isChanged, isShowAlert]);

  // 送信処理
  const formEl = useRef<HTMLFormElement>(null);
  const onSubmit = () => {
    setIsShowAlert(false);
    formEl.current?.submit();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form ref={formEl} action={action} onSubmit={methods.handleSubmit(onSubmit)} method="post">
          <input type="hidden" {...methods.register("authenticity_token")} />
          <input type="hidden" {...methods.register("_method")} />
          <BoxWithShadow>
            <StyledDescription>あなたの業界・仕事への想いを入力してください。</StyledDescription>
            <DefinitionList>
              <dt>なぜこの業界で働こうと思いましたか？</dt>
              <dd>
                <TextArea
                  name="form_user_frontend_self_pr_update[reason_for_apply]"
                  height={textAreaHeight}
                  placeholder={removeSpace(placeholderReasonForApply)}
                  isError={!!validateErrors?.reason_for_apply?.message}
                />
                {validateErrors?.reason_for_apply?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage message={validateErrors.reason_for_apply.message} />
                  </StyledErrorMessageOuter>
                )}
              </dd>
              <dt>お仕事をする上で大切にしていることは？</dt>
              <dd>
                <TextArea
                  name="form_user_frontend_self_pr_update[important_point]"
                  height={textAreaHeight}
                  placeholder={removeSpace(placeholderImportantPoint)}
                  isError={!!validateErrors?.important_point?.message}
                />
                {validateErrors?.important_point?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage message={validateErrors.important_point.message} />
                  </StyledErrorMessageOuter>
                )}
              </dd>
              <dt>この先仕事でやってみたいことやキャリアプラン、将来の夢は？</dt>
              <dd>
                <TextArea
                  name="form_user_frontend_self_pr_update[future_plan]"
                  height={textAreaHeight}
                  placeholder={removeSpace(placeholderFuturePlan)}
                  isError={!!validateErrors?.future_plan?.message}
                />
                {validateErrors?.future_plan?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage message={validateErrors.future_plan.message} />
                  </StyledErrorMessageOuter>
                )}
              </dd>
              <dt>自己PR</dt>
              <dd>
                <TextArea
                  name="form_user_frontend_self_pr_update[self_pr]"
                  height={textAreaHeight}
                  placeholder=""
                  isError={!!validateErrors?.self_pr?.message}
                />
                {validateErrors?.self_pr?.message && (
                  <StyledErrorMessageOuter>
                    <ValidationErrorMessage message={validateErrors.self_pr.message} />
                  </StyledErrorMessageOuter>
                )}
              </dd>
            </DefinitionList>
          </BoxWithShadow>
          <SubmitAndCancelButtons handleOnClick={openModal} appOsType={appOsType} />
        </form>
      </FormProvider>
      <Suspense fallback={null}>
        {isModalOpen && (
          <CancelModal isModalOpen={isModalOpen} closeModal={closeModal} returnPath={returnPath} />
        )}
      </Suspense>
    </>
  );
};
