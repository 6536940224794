/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import { FC, lazy, Suspense, useEffect, useMemo, useRef, useState } from "react";
import { Constants } from "@legacy_user_frontend/utils";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { BoxWithShadow } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/box_with_shadow";
import { SubmitAndCancelButtons } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/submit_and_cancel_buttons";
import { JobList } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/job_list";
import { showAlertBeforeBrowserBack } from "@legacy_root/user_frontend/components/pages/mypage/profiles/utils/show_alert_before_browser_back";
import { UserSkillsIdsProps, InputProps, SkillEditProps } from "./src/skill_edit_props";
import { StyledSkillItemListOuter } from "./src/styled_elements";
import { CategorizedSkill } from "./ui_parts/categorized_skill";
import { SkillLabels } from "./ui_parts/skill_labels";

const CancelModal = lazy(
  () => import("@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/cancel_modal"),
);

export const SkillEdit: FC<SkillEditProps> = ({
  action,
  returnPath,
  categorizedSkills,
  appOsType,
  isBiyo,
}) => {
  // 初期値の設定
  const defaultValues: UserSkillsIdsProps = useMemo(
    () => ({
      user_skills_ids: [],
    }),
    [],
  );
  categorizedSkills.forEach(({ businessTypes }) => {
    businessTypes.forEach((businessType) => {
      businessType.skillCategories.forEach(({ skills }) => {
        skills.forEach(({ id, registered }) => {
          if (registered) {
            defaultValues.user_skills_ids.push(`${id}`);
          }
        });
      });
    });
  });
  const methods = useForm<InputProps>({
    defaultValues: {
      authenticity_token: "",
      _method: "put",
      form_user_frontend_user_skill_update: defaultValues,
    },
  });

  //  リクエストフォームURLの定義
  const skillRequestFormUrl = isBiyo
    ? Constants.BIYO_SKILL_REQUEST_LINK
    : Constants.KAIGO_SKILL_REQUEST_LINK;

  // 変更の検出
  const watchedValues = useWatch({
    control: methods.control,
    name: "form_user_frontend_user_skill_update",
  });
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    if (JSON.stringify(watchedValues) !== JSON.stringify(defaultValues)) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [defaultValues, watchedValues]);

  // キャンセルボタン押下時に確認モーダルを表示する
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    if (isChanged) {
      setIsModalOpen(true);
      setIsShowAlert(false);
      return;
    }
    window.location.href = returnPath;
  };
  const closeModal = () => {
    setIsShowAlert(true);
    setIsModalOpen(false);
  };

  // ブラウザバックを検知して確認モーダルを表示する
  const ua = window.navigator.userAgent.toLowerCase();
  useEffect(() => {
    // 変更がない場合のほか、safariとiOSの場合（画面遷移後の動作に影響があるためブラウザバックを許容）は処理を抜ける
    if (
      ua.indexOf("iphone") > 0 ||
      ua.indexOf("ipod") > 0 ||
      (ua.indexOf("chrome") === -1 && ua.indexOf("safari") !== -1) ||
      !isChanged
    ) {
      return;
    }
    showAlertBeforeBrowserBack({ setIsShowAlert, setIsModalOpen });
  }, [isChanged, ua]);

  // 画面遷移を検知して確認アラートを表示する
  useEffect(() => {
    const showAlert = (event: BeforeUnloadEvent) => {
      if (!isChanged || !isShowAlert) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", showAlert);
    return () => window.removeEventListener("beforeunload", showAlert);
  }, [isChanged, isShowAlert]);

  // 送信処理
  const formEl = useRef<HTMLFormElement>(null);
  const onSubmit = () => {
    setIsShowAlert(false);
    formEl.current?.submit();
  };
  return (
    <>
      <FormProvider {...methods}>
        <form ref={formEl} action={action} onSubmit={methods.handleSubmit(onSubmit)} method="post">
          <input type="hidden" {...methods.register("authenticity_token")} />
          <input type="hidden" {...methods.register("_method")} />
          <input type="hidden" name="form_user_frontend_user_skill_update[user_skills_ids][]" />
          <BoxWithShadow>
            <p>
              あなたがこれまでに経験した業務等を選択してください。
              <br />
              ※自身のスキルや経験業務が選択肢にない場合、こちらの
              <a href={skillRequestFormUrl} target="_brank">
                専用フォーム
              </a>
              からリクエストすることが可能です
            </p>
            <SkillLabels categorizedSkills={categorizedSkills} />
            <StyledSkillItemListOuter>
              <JobList>
                {categorizedSkills.map(({ name, businessTypes }) => (
                  <CategorizedSkill key={name} name={name} businessTypes={businessTypes} />
                ))}
              </JobList>
            </StyledSkillItemListOuter>
          </BoxWithShadow>
          <SubmitAndCancelButtons handleOnClick={openModal} appOsType={appOsType} />
        </form>
      </FormProvider>
      <Suspense fallback={null}>
        {isModalOpen && (
          <CancelModal isModalOpen={isModalOpen} closeModal={closeModal} returnPath={returnPath} />
        )}
      </Suspense>
    </>
  );
};
