import { ComponentProps, FC } from "react";
import { LazyLoadImage } from "@root/shared/components/LazyLoadImage";
import { MultiLineText } from "@root/shared/components/MultiLineText";
import { StyledDiv, StyledHeading } from "./src/styled_elements";

interface Props {
  heading: string;
  body: string;
  images?: ImageProps[];
}

type ImageProps = ComponentProps<typeof LazyLoadImage>;

export const ClientBrandContents: FC<Props> = ({ heading, body, images }) => (
  <section className="p-job-group-inner">
    <StyledHeading>{heading}</StyledHeading>
    <div className="js-toggle-text-area">
      <MultiLineText text={body} />
    </div>
    <StyledDiv>
      {images &&
        images.map(({ src, alt, width, height, dummyImgPath }) => (
          <div key={src} className="columns">
            <LazyLoadImage
              key={src}
              src={src}
              dummyImgPath={dummyImgPath}
              alt={alt}
              width={width}
              height={height}
            />
          </div>
        ))}
    </StyledDiv>
  </section>
);
