/* eslint-disable react/jsx-props-no-spreading */
import { FC, lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  Pulldown,
  TextField,
  ValidationErrorMessage,
} from "@legacy_user_frontend/components/ui_parts";
import { DefinitionList } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/definition_list";
import { BoxWithShadow } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/box_with_shadow";
import { DeleteTextLinkButton } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/delete_text_button";
import { PrivateLabel } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/private_label";
import {
  CareerCassetteProps,
  FacilityTypesProps,
} from "@legacy_user_frontend/components/pages/mypage/profiles/user_career_edit/src/user_career_edit_props";
import {
  StyledCassetteHeadingOuter,
  StyledEmploymentPeriod,
  StyledErrorMessageOuter,
  StyledFacilityTypesAnnotation,
  StyledModalCloseButton,
  StyledModalCloseButtonOuter,
  StyledRemoveButtonOuter,
  StyledRemoveModalHeading,
  StyledRemoveModalOuter,
} from "@legacy_user_frontend/components/pages/mypage/profiles/user_career_edit/src/styled_elements";
import { Button } from "@root/shared/components/Button";
import styled from "styled-components";
import { JobType } from "./job_type";
import { Occupation } from "./occupation";

const Modal = lazy(() => import("@root/shared/components/Modal/legacy"));

const careJobTypeCategoryId = 5;

export const CareerCassette: FC<CareerCassetteProps> = ({
  cassetteIndex,
  isBiyo,
  careerIndex,
  jobTypeCategoryId,
  jobTypeId,
  workPatterns,
  removeField,
  employmentYearList,
  employmentMonthList,
  employmentPatternList,
  jobTypeCategoryList,
  workPatternList,
  yearsOfExperienceList,
  isRemoved,
}) => {
  const { formState, control, register } = useFormContext();
  const formName = `user_careers_update[user_careers_attributes[${careerIndex}]]`;
  const formErrors = formState.errors?.user_careers_update?.user_careers_attributes;
  const careerErrors = formErrors && formErrors[careerIndex];

  // 施設形態を複数登録するためのfieldArray
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${formName}[user_careers_facility_types_attributes]`,
  });
  const appendField = useCallback(
    () =>
      append({
        facility_type_id: "",
        years_of_experience: "",
      }),
    [append],
  );
  const removeFacilityType = (fieldId: number) => {
    remove(fieldId);
  };
  useEffect(() => {
    if (fields.length === 0) {
      appendField();
    }
  }, [appendField, fields.length]);

  // 職種・業種変更の際に確認モーダルを出すため、選択した内容をuseStateで管理する
  const [selectedJobTypeId, setSelectedJobTypeId] = useState<number | string | null>(
    jobTypeId || null,
  );
  const [selectedJobTypeCategoryId, setSelectedJobTypeCategoryId] = useState<
    number | string | null
  >(jobTypeCategoryId || null);

  // 職種・業種に応じて項目の変わる内容をuseStateで管理する
  const currentJobTypeCategoryList = jobTypeCategoryList.filter(
    (jobTypeCategory) => jobTypeCategory.value === selectedJobTypeCategoryId,
  );
  const [facilityTypesList, setFacilityTypesList] = useState<FacilityTypesProps[] | undefined>(
    currentJobTypeCategoryList.length ? currentJobTypeCategoryList[0].facilityTypes : undefined,
  );
  const defaultJobType = currentJobTypeCategoryList.length
    ? currentJobTypeCategoryList[0].jobTypes.filter(
        (jobType) => jobType.value === selectedJobTypeId,
      )
    : null;
  const [descriptionPlaceholder, setDescriptionPlaceholder] = useState<string | null>(
    defaultJobType && defaultJobType.length ? defaultJobType[0].descriptionPlaceholder : null,
  );

  // 削除確認モーダルの表示制御
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const handleOnClick = () => {
    setIsDeleteModalOpen(true);
  };
  const handleOnClose = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <>
      <BoxWithShadow id={`cassette-${cassetteIndex}`}>
        <>
          <input type="hidden" {...register(`${formName}[id]`)} />
          <StyledCassetteHeadingOuter>
            <p>職務経歴{careerIndex + 1}</p>
            <DeleteTextLinkButton type="button" onClick={handleOnClick}>
              削除する
            </DeleteTextLinkButton>
          </StyledCassetteHeadingOuter>
          <DefinitionList>
            <dt>
              会社名
              <span className="private-label-outer">
                <PrivateLabel />
              </span>
            </dt>
            <dd>
              <TextField
                name={`${formName}[company_name]`}
                placeholder="例）株式会社リジョブ"
                errorName={`${formName}[job_type_category_id]`}
              />
            </dd>
            <dt>
              店舗名・施設名
              <span className="private-label-outer">
                <PrivateLabel />
              </span>
            </dt>
            <dd>
              <TextField
                name={`${formName}[shop_name]`}
                placeholder="例）リジョブ池袋店"
                errorName={`${formName}[job_type_category_id]`}
              />
            </dd>
            <dt>雇用形態</dt>
            <dd>
              <Pulldown
                name={`${formName}[user_employment_pattern_id]`}
                placeholder="雇用形態を選択"
                list={employmentPatternList}
                errorName={`${formName}[job_type_category_id]`}
              />
            </dd>
            <dt>在籍期間</dt>
            <dd>
              <StyledEmploymentPeriod>
                <Pulldown
                  name={`${formName}[employment_start_year]`}
                  isError={careerErrors && careerErrors.employment_start_year}
                  placeholder="年"
                  list={employmentYearList}
                  errorName={`${formName}[job_type_category_id]`}
                />
                <Pulldown
                  name={`${formName}[employment_start_month]`}
                  isError={careerErrors && careerErrors.employment_start_month}
                  placeholder="月"
                  list={employmentMonthList}
                  errorName={`${formName}[job_type_category_id]`}
                />
                <div>〜</div>
                <Pulldown
                  name={`${formName}[employment_end_year]`}
                  isError={careerErrors && careerErrors.employment_end_year}
                  placeholder="年"
                  list={employmentYearList}
                  errorName={`${formName}[job_type_category_id]`}
                />
                <Pulldown
                  name={`${formName}[employment_end_month]`}
                  isError={careerErrors && careerErrors.employment_end_month}
                  placeholder="月"
                  list={employmentMonthList}
                  errorName={`${formName}[job_type_category_id]`}
                />
              </StyledEmploymentPeriod>
              <StyledFacilityTypesAnnotation>
                ※在籍中の場合、終了期間は未記入で登録してください。
              </StyledFacilityTypesAnnotation>
              {careerErrors && careerErrors.employment_start_year && (
                <StyledErrorMessageOuter>
                  <ValidationErrorMessage message={careerErrors.employment_start_year.message} />
                </StyledErrorMessageOuter>
              )}
              {careerErrors && careerErrors.employment_start_month && (
                <StyledErrorMessageOuter>
                  <ValidationErrorMessage message={careerErrors.employment_start_month.message} />
                </StyledErrorMessageOuter>
              )}
              {careerErrors && careerErrors.employment_end_year && (
                <StyledErrorMessageOuter>
                  <ValidationErrorMessage message={careerErrors.employment_end_year.message} />
                </StyledErrorMessageOuter>
              )}
              {careerErrors && careerErrors.employment_end_month && (
                <StyledErrorMessageOuter>
                  <ValidationErrorMessage message={careerErrors.employment_end_month.message} />
                </StyledErrorMessageOuter>
              )}
            </dd>
            <dt>役職</dt>
            <dd>
              <TextField
                name={`${formName}[occupation]`}
                placeholder="例）店長・マネージャー・リーダー"
                errorName={`${formName}[job_type_category_id]`}
              />
            </dd>
            <JobType
              isBiyo={isBiyo}
              careerIndex={careerIndex}
              jobTypeCategoryList={jobTypeCategoryList}
              setFacilityTypesList={setFacilityTypesList}
              setDescriptionPlaceholder={setDescriptionPlaceholder}
              removeFacilityType={remove}
              selectedJobTypeCategoryId={selectedJobTypeCategoryId}
              selectedJobTypeId={selectedJobTypeId}
              setSelectedJobTypeId={setSelectedJobTypeId}
              setSelectedJobTypeCategoryId={setSelectedJobTypeCategoryId}
              isRemoved={isRemoved}
              careJobTypeCategoryId={careJobTypeCategoryId}
            />
          </DefinitionList>
          {selectedJobTypeId && (
            <Occupation
              careerIndex={careerIndex}
              fields={fields}
              facilityTypesList={facilityTypesList}
              appendField={appendField}
              removeFacilityType={removeFacilityType}
              selectedJobTypeCategoryId={selectedJobTypeCategoryId}
              selectedJobTypeId={selectedJobTypeId}
              workPatternList={workPatternList}
              workPatterns={workPatterns}
              descriptionPlaceholder={descriptionPlaceholder}
              yearsOfExperienceList={yearsOfExperienceList}
              careJobTypeCategoryId={careJobTypeCategoryId}
            />
          )}
        </>
      </BoxWithShadow>
      <Suspense fallback={null}>
        {isDeleteModalOpen && (
          <Modal isShow={isDeleteModalOpen} onClose={handleOnClose} isBottomSheetMode={false}>
            <StyledRemoveModalOuter>
              <StyledRemoveModalHeading>
                職務経歴{careerIndex + 1}を本当に
                <br />
                削除してよろしいですか？
              </StyledRemoveModalHeading>
              <StyledRemoveButtonOuter>
                <ButtonWrapper>
                  <Button theme="primary" onClick={() => removeField(careerIndex)}>
                    削除する
                  </Button>
                </ButtonWrapper>
              </StyledRemoveButtonOuter>
              <StyledModalCloseButtonOuter>
                <StyledModalCloseButton type="button" onClick={handleOnClose}>
                  戻る
                </StyledModalCloseButton>
              </StyledModalCloseButtonOuter>
            </StyledRemoveModalOuter>
          </Modal>
        )}
      </Suspense>
    </>
  );
};

const ButtonWrapper = styled.div`
  height: 48px;
`;
